import React, { useEffect, useMemo, useState } from 'react';
import { connectHits, connectRange } from 'react-instantsearch-dom';
import type { Document, RangeRefinement } from 'front/app/catalog/types';
import type { Zone } from 'luxon';
import { DateTime as Luxon } from 'luxon';
import '@fullcalendar/react/dist/vdom'; // solves problems with vite HMR
import type { DatesSetArg, EventSourceInput } from '@fullcalendar/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { usePopper } from 'react-popper';
import { sum } from 'lodash';

type Props = {
    hits: Array<Document>;
    currentRefinement: RangeRefinement;
    refine: ({}: RangeRefinement) => {};
    colors: Array<{ label: string; color: string }>;
    timezone?: string | Zone;
    creditHourLabel: string;
    calendarLogo?: '';
    displayCreditHourType: boolean;
};

function Calendar({ hits, colors, timezone, refine, creditHourLabel, calendarLogo, displayCreditHourType }: Props) {
    const [activeEvent, setActiveEvent] = useState<{ event: null | Document; element: null | HTMLDivElement }>({
        event: null,
        element: null,
    });
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(activeEvent.element, popperElement);

    function getDate(date: number) {
        return Luxon.fromMillis(date * 1000, { zone: timezone });
    }

    const events = useMemo<EventSourceInput>(
        () =>
            hits.map((hit) => ({
                title: hit.name,
                start: getDate(hit.start!).toJSDate(),
                end: getDate(hit.end!).toJSDate(),
                color: hit.color?.value,
                display: 'block',
                extendedProps: hit,
            })),
        [hits],
    );

    function onEventClick({
        jsEvent,
        el,
        event,
    }: {
        jsEvent: React.MouseEvent<HTMLDivElement, MouseEvent>;
        el: HTMLDivElement;
        event: { extendedProps: Document };
    }) {
        jsEvent.stopPropagation();
        setActiveEvent({ event: event.extendedProps, element: el });
    }

    function onDatesSet(dates: DatesSetArg) {
        const getStart = () => {
            const currentMonth = new Date().getMonth();
            const viewingMonth = dates.view.currentStart.getMonth();
            if (currentMonth === viewingMonth) {
                return new Date().getTime() / 1000;
            }
            return dates.start.getTime() / 1000;
        };

        refine({
            min: getStart(),
            max: dates.end.getTime() / 1000,
        });
    }

    function closePopover() {
        setActiveEvent({ event: null, element: null });
    }

    useEffect(() => {
        document.addEventListener('click', closePopover);
        return () => document.removeEventListener('click', closePopover);
    }, []);

    return (
        <>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
                dayMaxEvents={5}
                eventClassNames="cursor-pointer"
                contentHeight="auto"
                eventClick={(event) => onEventClick(event as any)}
                datesSet={(dates) => onDatesSet(dates)}
            />

            <aside className="flex gap-6 mt-6">
                {colors.map((color) => (
                    <Legend key={color.label} label={color.label} bgColor={color.color} />
                ))}
            </aside>

            {activeEvent.element && activeEvent.event && (
                <div
                    className="z-[10000] bg-white rounded-xl shadow-xl overflow-scroll max-w-sm"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        className="relative flex justify-center items-center"
                        style={{ backgroundColor: activeEvent.event.color?.value }}
                    >
                        <span className="absolute top-4 right-4 cursor-pointer text-white" onClick={closePopover}>
                            <i className="icon-close" />
                        </span>

                        {activeEvent.event.calendarImageUrl ? (
                            <img src={activeEvent.event.calendarImageUrl} className="w-full h-32 object-contain" />
                        ) : calendarLogo ? (
                            <div className="text-xl font-bold text-white">
                                <img src={calendarLogo} className="w-48 h-12 my-6 object-contain m-2" />
                            </div>
                        ) : (
                            <div className="h-24" />
                        )}
                    </div>

                    <div className="p-6 grid gap-2 text-sm">
                        <div className="flex items-center gap-2 markup-body-md-bold text-primary-2">
                            <span>{getDate(activeEvent.event.start!).toFormat('DD')}</span>•
                            <span className="flex items-center gap-1">
                                {activeEvent.event.deliveryFormatIcon && (
                                    <span className={`icon-${activeEvent.event.deliveryFormatIcon}`} />
                                )}
                                <span>{activeEvent.event.deliveryFormat}</span>
                            </span>
                        </div>

                        <p className="markup-body-xl-bold">{activeEvent.event.name}</p>

                        <div>
                            <div>
                                {activeEvent.event.provider && (
                                    <p className="mt-0 markup-body-md text-gray-3">{activeEvent.event.provider}</p>
                                )}
                                {activeEvent.event.location && (
                                    <p className="mt-0 markup-body-md text-gray-3">{activeEvent.event.location}</p>
                                )}
                                {!!activeEvent.event.totalCreditHours &&
                                    activeEvent.event.totalCreditHours.length > 0 && (
                                        <div className="flex gap-2 markup-body-md text-gray-3">
                                            {displayCreditHourType
                                                ? // @ts-expect-error this event isn't typed
                                                  activeEvent.event.totalCreditHours.map((totalCreditHour, index) => {
                                                      return (
                                                          <div key={index}>
                                                              {`${totalCreditHour.total} (${totalCreditHour.type})`}
                                                          </div>
                                                      );
                                                  })
                                                : sum(
                                                      // @ts-expect-error this event isn't typed
                                                      activeEvent.event.totalCreditHours.map(
                                                          // @ts-expect-error this event isn't typed
                                                          (totalCreditHour) => totalCreditHour.total,
                                                      ),
                                                  ) +
                                                  ' ' +
                                                  creditHourLabel}
                                        </div>
                                    )}
                            </div>
                            {!!activeEvent.event.start && (
                                <p className="markup-body-md mt-4 text-primary-2 inline-flex items-center gap-x-1">
                                    <span className="icon-access_time relative mt-[-2px]" />
                                    <span>{`${getDate(activeEvent.event.start!).toFormat('h:mm a')} - ${getDate(
                                        activeEvent.event.end!,
                                    ).toFormat('h:mm a ZZZZ')}`}</span>
                                </p>
                            )}
                        </div>

                        {/*<p className="markup-body-md text-gray-3">@TODO description</p>*/}

                        <div className="my-4 grid gap-1">
                            {activeEvent.event.instructorsWithDetails.map((instructor, index) => (
                                <div key={index} className="flex items-center gap-2 markup-body-md-bold">
                                    {instructor.avatar_url && (
                                        <div className="w-8 h-8 rounded-full bg-gray-1 mr-2">
                                            <img src={instructor.avatar_url} />
                                        </div>
                                    )}
                                    {instructor.name}
                                </div>
                            ))}
                        </div>
                        <a
                            className="inline-flex items-center gap-1 text-primary-2 whitespace-nowrap"
                            href={activeEvent.event.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="icon-external-link text-sm" />
                            <span className="markup-a-sm ">See more event details</span>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

const ResultCalendar = connectHits(connectRange(Calendar));

export default function ({
    colors,
    timezone,
    creditHourLabel,
    calendarLogo,
    displayCreditHourType,
}: {
    colors: Array<{ label: string; color: string }>;
    timezone: string;
    creditHourLabel: string;
    calendarLogo?: string;
    displayCreditHourType: boolean;
}) {
    return (
        <ResultCalendar
            attribute="start"
            colors={colors}
            calendarLogo={calendarLogo}
            timezone={timezone}
            creditHourLabel={creditHourLabel}
            displayCreditHourType={displayCreditHourType}
        />
    );
}

export function Legend({ bgColor, label }: { bgColor: string; label: string }) {
    return (
        <div className="flex items-center gap-2">
            <span style={{ backgroundColor: bgColor }} className="w-3 h-3 flex-none rounded-full" />
            <span className="markup-sm">{label}</span>
        </div>
    );
}
