// @ts-nocheck
import { searchClient } from './algoliaSearchClient';
import React, { useEffect, useRef, useState } from 'react';
import { InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { CustomClearRefinements } from './customWidgets/clearRefinements';
import { LarutaRefinementList } from './customWidgets/refinementList';
import { CustomStats } from './customWidgets/stats';
import { CustomResultsSection } from './customWidgets/stateResults';
import { createURL, searchStateToUrl, urlToSearchState } from '../catalog/util';

const DEBOUNCE_TIME = 700;

type Props = {
    indexName: string;
};

function App({ indexName }: Props) {
    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const setStateId = useRef<any>();
    const [filtering, setFiltering] = useState<boolean>(false);

    useEffect(() => {
        const nextSearchState = urlToSearchState(location);

        if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
            setSearchState(nextSearchState);
        }
    }, [location]);

    function onSearchStateChange(nextSearchState: any) {
        clearTimeout(setStateId.current);

        setStateId.current = setTimeout(() => {
            history.pushState(nextSearchState, document.title, searchStateToUrl(location, nextSearchState));
        }, DEBOUNCE_TIME);

        setSearchState(nextSearchState);
    }

    return (
        <div>
            {/* <header className={'site-search-header'}>
                <div className={'mobile-search-switcher-container'} data-layout={'mobile'}>
                    <SearchSwitcher currentSearchName="Site Search" />
                </div>
            </header> */}

            <div className="pt-4 container lg:grid lg:grid-cols-[20rem,1fr] gap-12">
                {/* Widgets need to live inside <InstantSearch> */}
                <InstantSearch
                    indexName={indexName}
                    searchClient={searchClient}
                    searchState={searchState}
                    onSearchStateChange={onSearchStateChange}
                    createURL={createURL}
                >
                    <aside
                        id="filters"
                        className={`
                        ${filtering ? 'translate-y-0' : 'translate-y-full'}
                        fixed z-modal lg:z-10 inset-0
                        transform transition-transform duration-300
                        lg:relative bg-primary-1 lg:bg-white
                        lg:transform-none lg:transition-none
                    `}
                    >
                        <div className="max-h-screen lg:max-h-none lg:sticky staff-bar-6 flex flex-col gap-6 p-4 lg:px-0 overflow-y-auto">
                            <button
                                className="lg:hidden absolute top-3 right-3 icon-close text-13 text-primary-2 opacity-50 hover:opacity-100"
                                onClick={() => setFiltering(false)}
                            />
                            <div className="pt-6 lg:pt-0 flex items-center justify-between">
                                <h3 className="markup-h5 text-primary-2 mb-0">Filter Fields</h3>
                                <CustomClearRefinements />
                            </div>

                            <div className="grid gap-3 pb-6">
                                <LarutaRefinementList attribute="DataType" />
                            </div>
                        </div>
                    </aside>

                    <button
                        id="mobile-filters-button"
                        className="z-modal fixed left-0 bottom-0 lg:hidden w-full bg-accent-3 text-white text-12 text-center font-semibold uppercase p-3"
                        onClick={() => setFiltering(!filtering)}
                    >
                        {filtering ? (
                            <>
                                Show Results <CustomStats />
                            </>
                        ) : (
                            'Show Filters'
                        )}
                    </button>

                    <main className="pt-6">
                        <SearchBox autoFocus={true} translations={{ placeholder: 'Type your keyword here…' }} />

                        <CustomResultsSection />
                    </main>
                </InstantSearch>
            </div>
        </div>
    );
}

export default App;
