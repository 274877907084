import Alpine from 'alpinejs';

Alpine.data('bundle', (customizable) => ({
    included: !customizable,
    _deliveryFormat: '' as 'online' | 'in_person' | '',
    _printedEMaterials: true,

    _firstSelectedDeliveryFormat: '' as 'online' | 'in_person' | '',

    get deliveryFormat() {
        if (!this.included) return '';
        return this._deliveryFormat;
    },

    set deliveryFormat(value: 'online' | 'in_person' | '') {
        this._deliveryFormat = value;

        if (this._firstSelectedDeliveryFormat === '') {
            this._firstSelectedDeliveryFormat = value;
        }
    },

    set printedEMaterials(value: boolean) {
        this._printedEMaterials = value;
    },

    get printedEMaterials() {
        return this.included && this._printedEMaterials;
    },

    printedEMaterialsClicked() {
        if (this.included) return;
        this.included = true;
        this.$nextTick(() => (this._printedEMaterials = true));
    },

    init() {
        this.$watch('_firstSelectedDeliveryFormat', (value: 'online' | 'in_person' | '') => {
            if (value === '') return;

            this._deliveryFormat = value;
        });
    },
}));
