// @ts-nocheck
import Button from 'front/ui/forms/inputs/Button';
import React from 'react';

import { connectInfiniteHits } from 'react-instantsearch-dom';

import { HitFactory } from '../hitFactory';

const InfiniteHits = ({ hits, hasMore, refineNext }) => (
    <div className="grid pb-24">
        {hits.map((hit) => (
            <HitFactory key={hit.objectID} hit={hit} />
        ))}
        {hasMore && (
            <Button is="link" onClick={refineNext}>
                Load more
            </Button>
        )}
    </div>
);

export const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);
