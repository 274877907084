import React, { useEffect, useRef, useState } from 'react';
import { createURL, searchStateToUrl, urlToSearchState } from '../util';
import { Configure, InstantSearch, ScrollTo } from 'react-instantsearch-dom';
import SearchInput from './SearchInput';
import DateRangeFilter from './DateRangeFilter';
import SelectFilter from './SelectFilter';
import Clear from './Clear';
import ResultList from './ResultList';
import CurrentRefinements from './CurrentRefinements';
import algoliasearch from 'algoliasearch/lite';
import ResultCalendar from './ResultCalendar';
import type { Display } from 'front/app/catalog/types';
import RefinementTabs from 'front/app/catalog/components/RefinementTabs';
import Pagination from 'front/app/catalog/components/Pagination';

const searchClient = algoliasearch(window.Sail.algolia.application_id, window.Sail.algolia.search_only_api_key);

export function Field({
    label,
    children,
    uppercase,
    className = '',
}: {
    label?: string;
    children: React.ReactNode;
    uppercase?: boolean;
    className?: string;
}) {
    return (
        <div className={`${className} grid grid-cols-1 gap-1`}>
            {label && (
                <label className={`${uppercase ? 'uppercase' : ''} truncate markup-body-sm-bold text-gray-4`}>
                    {label}
                </label>
            )}
            {children}
        </div>
    );
}

type Props = {
    displayName: string;
    indexName: string;
    tabs: Array<string>;
    colors: Array<{
        label: string;
        color: string;
    }>;
    timezone: string;
    creditHourLabel: string;
    calendarLogo?: string;
    displayCreditHourType: boolean;
};

const PER_PAGE_LIST = 8;
const PER_PAGE_CALENDAR = 1000;
const now = new Date();

const SYNC_URL_DEBOUNCE_MS = 700;

export default function SearchCatalog({
    displayName,
    indexName,
    tabs,
    colors,
    timezone,
    creditHourLabel,
    calendarLogo,
    displayCreditHourType,
}: Props) {
    const location = window.location;
    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const display = searchState.display === 'calendar' ? 'calendar' : ('list' as Display);

    const [filtering, setFiltering] = useState<boolean>(false);

    const syncUrlTimeout = useRef<NodeJS.Timeout | undefined>();
    const onPage = useRef<boolean>(true);

    useEffect(() => {
        onPage.current = true;
        return () => {
            onPage.current = false;
        };
    }, []);

    function setDisplay(display: Display) {
        onSearchStateChange({ ...searchState, display });
    }

    function onSearchStateChange(nextSearchState: any) {
        if (JSON.stringify(searchState) === JSON.stringify(nextSearchState)) {
            return;
        }

        setSearchState(nextSearchState);

        if (syncUrlTimeout.current) {
            clearTimeout(syncUrlTimeout.current);
        }

        syncUrlTimeout.current = setTimeout(() => {
            if (!onPage) return;
            history.pushState(nextSearchState, document.title, searchStateToUrl(location, nextSearchState));
        }, SYNC_URL_DEBOUNCE_MS);
    }

    useEffect(() => {
        window.onpopstate = function onpopstate() {
            onSearchStateChange(urlToSearchState(location));
        };

        return () => {
            window.onpopstate = () => {};
        };
    }, []);

    const addDay = (date: Date) => {
        const result = new Date(date);

        result.setDate(result.getDate() + 1);

        return result;
    };

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={indexName}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
        >
            <Configure
                filters={
                    // Force non-dated products to end
                    display === 'list'
                        ? 'displayInCatalog:true AND (start = 9999999999 OR start >=' +
                          now.getTime() / 1000 +
                          ' OR end >=' +
                          addDay(now).getTime() / 1000 +
                          ')'
                        : 'displayOnCalendar:true'
                }
                hitsPerPage={display === 'list' ? PER_PAGE_LIST : PER_PAGE_CALENDAR}
            />

            <header className="relative">
                <img
                    className="absolute inset-0 w-full h-full object-cover"
                    src="https://assets.sailamx.com/assets/backgrounds/product-catalog-header-image.jpeg"
                    alt=""
                />
                <div className="relative container">
                    <div className="catalog-switch pt-10 mb-3">
                        <h5 className="text-12 text-white tracking-widest leading-none">Search Type</h5>
                        <h1 className="text-36 text-white font-medium">{displayName}</h1>
                    </div>

                    <RefinementTabs tabs={tabs} attribute="tabs" />
                </div>
            </header>

            <div className="container lg:grid lg:grid-cols-[20rem,1fr] gap-12">
                <aside
                    id="filters"
                    className={`
                        ${filtering ? 'translate-y-0' : 'translate-y-full'}
                        fixed z-modal lg:z-10 inset-0
                        transform transition-transform duration-300
                        lg:relative bg-primary-1 lg:bg-white
                        lg:transform-none lg:transition-none
                    `}
                >
                    <div className="max-h-screen lg:max-h-none lg:sticky staff-bar-6 flex flex-col gap-6 p-4 lg:px-0 overflow-y-auto scrollbar-none">
                        <button
                            className="lg:hidden absolute top-3 right-3 icon-close text-13 text-primary-2 opacity-50 hover:opacity-100"
                            onClick={() => setFiltering(false)}
                        />
                        <div className="pt-6 lg:pt-0 flex items-center justify-between">
                            <h3 className="markup-h5 text-primary-2 mb-0">Filter Fields</h3>
                            <Clear clearsQuery />
                        </div>

                        <Field label="Search" uppercase>
                            <SearchInput />
                        </Field>

                        {display === 'list' && <DateRangeFilter placeholder="Start Date" attribute="start" />}

                        <Field label="Delivery Formats" uppercase>
                            <SelectFilter placeholder="All Delivery Formats" attribute="deliveryFormat" limit={100} />
                        </Field>
                        <Field label="Field of Study" uppercase>
                            <SelectFilter placeholder="All Fields Of Study" attribute="fieldsOfStudy" limit={100} />
                        </Field>
                        <Field label="Instructor" uppercase>
                            <SelectFilter placeholder="All Instructors" attribute="instructors" limit={350} />
                        </Field>
                        <Field label="Credit Hours" uppercase>
                            <SelectFilter placeholder="All Credit Hours" attribute="creditHours" limit={100} />
                        </Field>
                        <Field label="Location" uppercase>
                            <SelectFilter placeholder="All Locations" attribute="location" limit={200} />
                        </Field>
                        <Field label="Provider" uppercase>
                            <SelectFilter placeholder="All Providers" attribute="provider" limit={200} />
                        </Field>

                        <Clear clearsQuery />
                    </div>
                </aside>

                <button
                    id="mobile-filters-button"
                    className="z-modal fixed left-0 bottom-0 lg:hidden w-full bg-accent-3 text-white text-12 text-center font-semibold uppercase p-3"
                    onClick={() => setFiltering(!filtering)}
                >
                    {filtering ? 'Hide' : 'Show'} Filters
                </button>

                <main>
                    <div className="flex py-4 gap-12">
                        <ScrollTo>
                            {searchState.query && (
                                <div className="my-1.5 markup-body-sm text-primary-2">
                                    Searching for <strong>{searchState.query ?? ''}</strong>
                                </div>
                            )}
                            <CurrentRefinements display={display} />
                        </ScrollTo>

                        <nav className="hidden lg:flex ml-auto items-center gap-12">
                            <button
                                className={`${
                                    display === 'calendar'
                                        ? 'border-primary-3'
                                        : 'border-transparent hover:border-primary-2'
                                } border-b inline-flex items-center gap-2 text-primary-3 py-1`}
                                onClick={() => setDisplay('calendar')}
                            >
                                <i className="icon-Transcript" />
                                <span className="markup-body-md-bold">
                                    Calendar<span className="hidden xl:inline-block">&nbsp;View</span>
                                </span>
                            </button>

                            <button
                                className={`${
                                    display === 'list'
                                        ? 'border-primary-3'
                                        : 'border-transparent hover:border-primary-2'
                                } border-b inline-flex items-center gap-2 text-primary-3 py-1`}
                                onClick={() => setDisplay('list')}
                            >
                                <i className="icon-filters-calendar" />
                                <span className="markup-body-md-bold">
                                    List<span className="hidden xl:inline-block">&nbsp;View</span>
                                </span>
                            </button>
                        </nav>
                    </div>

                    <section className="mt-5">
                        {display === 'list' ? (
                            <ResultList
                                timezone={timezone}
                                creditHourLabel={creditHourLabel}
                                displayCreditHourType={displayCreditHourType}
                            />
                        ) : (
                            <ResultCalendar
                                calendarLogo={calendarLogo}
                                colors={colors}
                                timezone={timezone}
                                creditHourLabel={creditHourLabel}
                                displayCreditHourType={displayCreditHourType}
                            />
                        )}
                    </section>
                    {display === 'list' && (
                        <nav className="mt-12 lg:-mx-4 z-20 bg-white border-t-2 border-primary-2 lg:sticky lg:bottom-0">
                            <Pagination hitsPerPage={PER_PAGE_LIST} />
                        </nav>
                    )}
                </main>
            </div>
        </InstantSearch>
    );
}
