import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import MultiSelectInput from 'front/ui/forms/inputs/MultiSelectInput';
import type { RefinementListProvided } from 'react-instantsearch-core';

type Props = RefinementListProvided & {
    placeholder?: string;
};

function SelectFilter({ items, currentRefinement, refine, placeholder }: Props) {
    return (
        <MultiSelectInput
            placeholder={placeholder}
            value={currentRefinement || []}
            options={items.map((item) => {
                return {
                    label: `${item.label} (${item.count})`,
                    value: item.label,
                };
            })}
            onChange={refine}
        />
    );
}

export default connectRefinementList<Props>(SelectFilter);
