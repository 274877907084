import Alpine from 'alpinejs';
import type { Option } from 'front/ui/forms/types';
import { keyBy } from 'lodash/fp';

type CountryOption = Option<string, { iso: string; requires_state: boolean }>;
type StateOption = Option<string, { abbreviation: string }>;

declare global {
    interface Window {
        countryOptions: Array<CountryOption>;
        stateOptions: Array<StateOption>;
    }
}

Alpine.data('placesAutocomplete', () => ({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    county: '',

    get requiresState() {
        if (!this.country) {
            return true;
        }

        if (this.country === 'US') {
            // new options
            return true;
        }

        // legacy options
        const country = this.getCountryByUuid(this.country);
        return country ? !!country?.requires_state : true;
    },

    getStateByAbbreviation(abbreviation: string | null): StateOption | null {
        if (!abbreviation) {
            return null;
        }

        return window.stateOptions.find((state) => (state.abbreviation || state.value) === abbreviation) || null;
    },

    getCountryByIso(iso: string | null): CountryOption | null {
        if (!iso) {
            return null;
        }

        return window.countryOptions.find((country) => (country.iso || country.value) === iso) || null;
    },

    getCountryByUuid(uuid: string | null): CountryOption | null {
        if (!uuid) {
            return null;
        }

        return window.countryOptions.find((country) => country.value === uuid) || null;
    },

    init() {
        this.address1 = this.$el!.dataset.address1 || '';
        this.address2 = this.$el!.dataset.address2 || '';
        this.city = this.$el!.dataset.city || '';
        this.state = this.$el!.dataset.state || '';
        this.zipcode = this.$el!.dataset.zipcode || '';
        this.country = this.$el!.dataset.country || '';
        this.county = this.$el!.dataset.county || '';

        const inputEl = this.$refs.address1 as HTMLInputElement;

        if (!window.google) {
            return;
        }

        if (!(inputEl instanceof HTMLInputElement)) {
            return;
        }

        if (window.google?.maps?.places?.Autocomplete === undefined) {
            return;
        }

        const autocompleteInput = new google.maps.places.Autocomplete(inputEl, {
            bounds: new google.maps.LatLngBounds(
                new google.maps.LatLng(...window.Sail.general.state_coordinates),
                new google.maps.LatLng(...window.Sail.general.state_coordinates),
            ),
            types: ['address'],
        });

        google.maps.event.addDomListener(inputEl, 'keydown', (event: any) => {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });

        this.$watch('address1', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    address1: value,
                });
            }
        });

        this.$watch('address2', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    address2: value,
                });
            }
        });

        this.$watch('city', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    city: value,
                });
            }
        });

        this.$watch('state', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    state: value,
                });
            }
        });

        this.$watch('zipcode', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    zipcode: value,
                });
            }
        });

        this.$watch('country', (value: string) => {
            /* @ts-expect-error Todo */
            if (Livewire) {
                /* @ts-expect-error Todo */
                Livewire.emit('update-address', {
                    country: value,
                });
            }
        });

        autocompleteInput.addListener('place_changed', () => {
            const place = autocompleteInput.getPlace();

            if (!place) {
                return;
            }

            const addressComponents = keyBy((component: google.maps.GeocoderAddressComponent) => component.types[0])(
                place.address_components || [],
            );

            if (Object.keys(addressComponents).length === 0) {
                return;
            }

            const selectedCountry = this.getCountryByIso(addressComponents.country?.short_name);

            const baseZipCode = addressComponents.postal_code?.long_name || '';
            const zipcodeSuffix = addressComponents.postal_code_suffix?.long_name || null;

            this.address1 = `${addressComponents.street_number?.long_name || ''} ${
                addressComponents.route?.long_name
            }`.trim();
            this.address2 = '';
            this.city = addressComponents.locality?.long_name || '';
            this.state =
                addressComponents.country?.short_name === 'US'
                    ? this.getStateByAbbreviation(addressComponents.administrative_area_level_1?.short_name)?.value ||
                      ''
                    : '';
            this.zipcode = zipcodeSuffix ? `${baseZipCode}-${zipcodeSuffix}` : baseZipCode;
            this.country = selectedCountry?.value || '';
            this.county = addressComponents.administrative_area_level_2?.long_name || '';

            this.$nextTick(() => {
                // Required to get correct address1 value to save when editing card address in cart
                const address1 = this.$el!.querySelector('[x-model=address1]') as HTMLInputElement;

                if (address1) {
                    address1.value = this.address1;
                }

                // Required to save correct value
                this.$el?.querySelector('[x-model=address1]')?.dispatchEvent(new Event('change'));
                this.$el?.querySelector('[x-model=address2]')?.dispatchEvent(new Event('change'));
                this.$el?.querySelector('[x-model=city]')?.dispatchEvent(new Event('change'));
                this.$el?.querySelector('[x-model=state]')?.dispatchEvent(new Event('change'));
                this.$el?.querySelector('[x-model=zipcode]')?.dispatchEvent(new Event('change'));
                this.$el?.querySelector('[x-model=country]')?.dispatchEvent(new Event('change'));

                // Required for tax change detection to work in cart
                this.$el?.querySelector('[x-model=address2]')?.dispatchEvent(new Event('input'));
                this.$el?.querySelector('[x-model=city]')?.dispatchEvent(new Event('input'));
                this.$el?.querySelector('[x-model=state]')?.dispatchEvent(new Event('input'));
                this.$el?.querySelector('[x-model=zipcode]')?.dispatchEvent(new Event('input'));
                this.$el?.querySelector('[x-model=country]')?.dispatchEvent(new Event('input'));
            });
        });
    },
}));
