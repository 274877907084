import { DateTime as Luxon, LocalZone } from 'luxon';

export function $(selector: string, scope: ParentNode = document) {
    return scope.querySelector(selector);
}

export function $$(selector: string, scope: ParentNode = document): Array<HTMLElement> {
    return Array.from(scope.querySelectorAll(selector));
}

type Method = 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';

export function ajax(method: Method, url: string, data?: object | FormData) {
    if (data && data instanceof FormData) {
        return fetch(url, {
            method,
            body: data,
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': ($('meta[name="csrf-token"]') as HTMLMetaElement).content,
            },
        }).then((response) => (response.status === 204 ? Promise.resolve(null) : response.json()));
    }

    return fetch(url, {
        method,
        body: data ? JSON.stringify(data) : undefined,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': ($('meta[name="csrf-token"]') as HTMLMetaElement).content,
        },
    }).then((response) => response.json());
}

const loadingScripts: { [key: string]: Promise<void> } = {};

export function loadScript(src: string): Promise<void> {
    if (src in loadingScripts) {
        return loadingScripts[src];
    }

    loadingScripts[src] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;

        script.onload = () => resolve();
        script.onerror = () => reject(`Error loading ${src}`);

        document.head.appendChild(script);
    });

    return loadingScripts[src];
}

// Get element's offset from top of document: https://stackoverflow.com/a/24829409/6374824
function getRealTop(element: HTMLElement) {
    let yPosition = 0;

    while (element) {
        yPosition += element.offsetTop - element.scrollTop + element.clientTop;
        element = element.offsetParent as HTMLElement;
    }

    return yPosition;
}

declare global {
    interface Window {
        scrollToElement: (href: string, offset: number) => void;
    }
}

window.scrollToElement = (href: string, offset: number) => {
    const element = document.querySelector(`${href}`);

    if (element) {
        window.scrollTo({ behavior: 'smooth', top: getRealTop(element as HTMLElement) - offset });
    }
};

export function setBrowserFullscreen(isFullscreen: boolean) {
    if (isFullscreen) {
        const requestFunc =
            document.documentElement.requestFullscreen ||
            (document.documentElement as any).webkitRequestFullscreen ||
            (document.documentElement as any).webkitRequestFullScreen ||
            (document.documentElement as any).webkitEnterFullscreen ||
            (document.documentElement as any).webkitEnterFullScreen;
        const requestFullscreen = requestFunc.bind(document.documentElement);

        requestFullscreen();
    } else {
        const exitFunc =
            document.exitFullscreen || (document as any).webkitExitFullscreen || (document as any).webkitExitFullScreen;
        const exitFullscreen = exitFunc.bind(document);

        exitFullscreen();
    }
}

export function fixTimezone(zone: string) {
    return Luxon.fromObject({ zone }).setZone(new LocalZone(), { keepLocalTime: true });
}
