// @ts-nocheck
import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { LarutaRefinementListItem } from '../refinements/LarutaRefinementListItem';

/*
 * refinementLists defines the available facets in search
 * they are hard coded for now, with plans to make dynamic in the future  */
const refinementLists = [
    { label: 'Blog Post' },
    { label: 'Page' },
    { label: 'Product', group: 'product-catalog' },
    { label: 'On-Demand', group: 'product-catalog' },
    { label: 'Event', group: 'product-catalog' },
    { label: 'Ethics Opinion' },
];

/*
 * Check if a refinement is enabled
 * Blog, Ethics Opinion, and Resource Library can be shown/hidden with ACF in feature options */
const refinementIsEnabled = function (refinementName) {
    /* @TODO not sure which ones we need here */
    const SiteSearchFeatureVars = { EnabledFacets: { Blog: true, EthicsOpinion: false } };
    if (refinementName === 'Blog Posts' && !SiteSearchFeatureVars.EnabledFacets.Blog) {
        return false;
    }

    if (refinementName === 'Ethics Opinion' && !SiteSearchFeatureVars.EnabledFacets.EthicsOpinion) {
        return false;
    }

    return true;
};

/*
 * Filters Refinement Lists if they are enabled in feature options */
const filterRefinementLists = function () {
    const filteredRefinementLists = [];
    for (const refinementList of Object.values(refinementLists)) {
        if (refinementIsEnabled(refinementList.label)) {
            filteredRefinementLists.push(refinementList);
        }
    }

    return filteredRefinementLists;
};

/*
 * CustomRefinementList
 * --------------------
 * This implementation is very different from Algolia's, which does not
 * support a "nested" refinement list, which is what we are going for. */
const CustomRefinementList = ({ currentRefinement, items, refine }) => {
    return filterRefinementLists().map((refinementList, index) => {
        const item = items.find((item) => item.label === refinementList.label);
        const { isRefined } = items.find((item) => item.label === refinementList.label) || { isRefined: false };

        return (
            <div key={refinementList.label}>
                <ul>
                    <LarutaRefinementListItem
                        staticItem={refinementList}
                        item={item}
                        index={index}
                        isRefined={isRefined}
                        refinementListProps={{
                            items,
                            currentRefinement,
                            refine,
                        }}
                        key={refinementList.label}
                        listItemIcon={refinementList.listItemIcon}
                        afterRefinements={refinementList.afterRefinements}
                    />
                </ul>

                {refinementList.afterContent ? refinementList.afterContent : null}
            </div>
        );
    });
};

export const LarutaRefinementList = connectRefinementList(CustomRefinementList);
