import Alpine from 'alpinejs';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { postViewerEvent } from 'front/app/eLearning/viewerEvents';
import Echo from 'laravel-echo';
import * as Sentry from '@sentry/browser';

type QuestionType = 'radio' | 'select' | 'text' | 'checkIn';

type CheckInResponseReceived = {
    checkInRequest: {
        uuid: string;
    };
};
type CheckinRequest = {
    checkInRequest: {
        uuid: string;
    };
    question?: string;
    questionOptions?: Array<{ label: string; value: string }>;
    questionType?: QuestionType;
};

const questionTypes: Record<QuestionType, { timeout: number; title: string }> = {
    radio: { timeout: 90, title: 'Please Submit Your Response' },
    select: { timeout: 90, title: 'Please Submit Your Response' },
    text: { timeout: 90, title: 'Please Submit Your Response' },
    checkIn: { timeout: 60, title: 'Please Confirm Your Attendance' },
};

Alpine.data('checkIns', () => ({
    isModalOpen: false,
    isLoading: false,
    attendanceTitle: '',
    attendanceQuestion: '',
    attendanceOptions: [] as Array<{ label: string; value: any }>,
    type: '',
    errorMessage: '',
    selectedAnswer: null as null | string,
    eventSessionUuid: '',
    streamUuid: '',
    sessionRegistrationUuid: '',
    checkinRequestUuid: '',
    timeout: 0,
    timeoutInterval: undefined as number | undefined,
    isSuccess: false,

    setModalOpen(open: boolean) {
        this.isModalOpen = open;
    },

    init() {
        this.eventSessionUuid = this.$el!.dataset.eventSessionUuid!;
        this.streamUuid = this.$el!.dataset.streamUuid!;
        this.sessionRegistrationUuid = this.$el!.dataset.sessionRegistrationUuid!;

        const echo = new Echo({
            broadcaster: 'pusher',
            key: this.$el!.dataset.pusherKey!,
            cluster: this.$el!.dataset.pusherCluster!,
        });

        echo.channel(`checkins.${this.sessionRegistrationUuid}`).listen(
            '.CheckInResponseTriggered',
            (event: CheckinRequest) => {
                this.checkinRequestUuid = event.checkInRequest.uuid;
                this.triggerAttendanceModal({
                    question: event.question,
                    type: event.questionType || 'checkIn',
                    options: event.questionOptions?.map((option) => {
                        return {
                            label: option.label,
                            value: option.label,
                        };
                    }),
                });
            },
        );
        echo.channel(`checkins.${this.sessionRegistrationUuid}`).listen(
            '.CheckInResponseReceived',
            (event: CheckInResponseReceived) => {
                if (this.checkinRequestUuid === event.checkInRequest.uuid) {
                    this.resetModal();
                }
            },
        );
    },

    triggerAttendanceModal({
        question,
        options = [],
        type = 'checkIn',
    }: {
        question?: string;
        options?: Array<{ label: string; value: string }>;
        type?: 'radio' | 'select' | 'text' | 'checkIn';
    }) {
        clearInterval(this.timeoutInterval);
        postViewerEvent('user_check_in_triggered', this.$el!.dataset.eventSessionUuid!);

        playCheckInSound();

        this.attendanceTitle = questionTypes[type].title;
        this.attendanceQuestion = question || '';
        this.attendanceOptions = options;
        this.type = type;
        this.timeout = questionTypes[type].timeout;
        this.isModalOpen = true;

        this.timeoutInterval = setInterval(() => {
            if (this.timeout <= 0) {
                clearInterval(this.timeoutInterval);

                if (!this.isLoading) {
                    axios
                        .post(
                            `/internal-api/e-learning/${this.eventSessionUuid}/check-in/response/${this.checkinRequestUuid}/timed-out`,
                        )
                        .then(
                            (
                                response: AxiosResponse<App.Context.ELearning.DataTransferObjects.CheckIn.CheckInResponseData>,
                            ) => {
                                if (response.data.timed_out_at) {
                                    postViewerEvent('user_check_in_timed_out', this.eventSessionUuid, {
                                        checkinRequestUuid: this.checkinRequestUuid,
                                    });
                                }
                            },
                        );

                    this.resetModal();
                }

                return;
            }

            this.timeout = this.timeout - 1;
        }, 1000) as any;
    },

    confirmAttendance() {
        if (this.type !== 'checkIn' && !this.selectedAnswer) {
            return;
        }

        this.errorMessage = '';
        this.isLoading = true;

        axios
            .post(`/internal-api/e-learning/${this.eventSessionUuid}/check-in/response/${this.checkinRequestUuid}`, {
                answer: this.selectedAnswer,
            })
            .then(() => {
                postViewerEvent('user_check_in_response_submitted', this.eventSessionUuid, {
                    check_in_request_uuid: this.checkinRequestUuid,
                    answer: this.selectedAnswer,
                });

                this.isSuccess = true;

                setTimeout(() => {
                    this.resetModal();
                }, 1500);
            })
            .catch((error) => {
                this.errorMessage = 'Something went wrong while submitting your answer, please try again.';

                Sentry.captureException(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    resetModal() {
        this.isModalOpen = false;

        clearInterval(this.timeoutInterval);
        this.isSuccess = false;
        this.isLoading = false;
        this.errorMessage = '';
        this.attendanceQuestion = '';
        this.attendanceOptions = [];
        this.type = '';
        this.selectedAnswer = null;
    },

    get isSubmitDisabled() {
        return this.isLoading || (this.type !== 'checkIn' && !this.selectedAnswer);
    },
}));

function playCheckInSound() {
    try {
        const notification = new Audio();
        notification.src = 'https://assets.sailamx.com/assets/audio/webcast_check_in_sound.mp3';
        notification.play();
    } catch (error) {
        console.error(error);

        Sentry.captureException(error);
    }
}
