import Alpine from 'alpinejs';
import Echo from 'laravel-echo';

Alpine.data('stream', () => ({
    streamUuid: '',

    init() {
        this.streamUuid = this.$el!.dataset.streamUuid!;

        const echo = new Echo({
            broadcaster: 'pusher',
            key: this.$el!.dataset.pusherKey!,
            cluster: this.$el!.dataset.pusherCluster!,
        });

        echo.channel(`livestreams.${this.streamUuid}`).listen('.LiveStreamEndedEvent', () => {
            window.location.reload();
        });
    },
}));
