import DateRangeInput from 'front/ui/forms/inputs/DateRangeInput';
import { connectRange } from 'react-instantsearch-dom';
import React from 'react';
import type { RangeRefinement } from 'front/app/catalog/types';

const oneDayInSeconds = 24 * 60 * 60 - 1;

function DateRange({
    currentRefinement,
    refine,
}: {
    currentRefinement: RangeRefinement;
    refine: ({}: RangeRefinement) => {};
}) {
    return (
        <DateRangeInput
            start={currentRefinement.min}
            end={currentRefinement.max ? currentRefinement.max - oneDayInSeconds : currentRefinement.max}
            onChange={(values) => {
                refine({ min: values.start, max: values.end ? values.end + oneDayInSeconds : undefined });
            }}
        />
    );
}

export default connectRange(DateRange);
