/**
 * @ts-nocheck
 * eslint-disable-next-line @typescript-eslint/ban-ts-comment
 */
import React from 'react';
import { LarutaHit } from './larutaHit';

export const EthicsOpinionHit = ({ hit }: { hit: any }) => (
    <LarutaHit
        /* eslint-disable-next-line react/prop-types */
        hitName={hit.name}
        /* eslint-disable-next-line react/prop-types */
        hitDataType={hit.DataType}
        /* eslint-disable-next-line react/prop-types */
        hitDetails={[hit.DatePublished]}
        /* eslint-disable-next-line react/prop-types */
        hitPermalink={hit.Permalink}
    />
);
