import React from 'react';
import { connectPagination, connectStats } from 'react-instantsearch-dom';

type Props = {
    canRefine: boolean; // can paginate
    currentRefinement: number; // current page
    nbPages: number; // total pages
    nbHits: number; // total hits
    hitsPerPage: number;
    refine: (page: number) => void; // change page
};

function Pagination({ canRefine, currentRefinement, nbPages, nbHits, hitsPerPage, refine }: Props) {
    if (!canRefine) {
        return null;
    }

    const prevDisabled = currentRefinement === 1;
    const nextDisabled = currentRefinement === nbPages;

    const resultsFrom = (currentRefinement - 1) * hitsPerPage + 1;
    const resultsTo = currentRefinement * hitsPerPage > nbHits ? nbHits : currentRefinement * hitsPerPage;

    return (
        <nav
            role="navigation"
            className="flex flex-col md:flex-row justify-between items-start md:items-center text-gray-3 px-4 py-3"
        >
            <div className="mb-4 md:mb-0 markup-body-md text-gray-3">
                Showing <strong>{resultsFrom}</strong> to <strong>{resultsTo}</strong> of <strong>{nbHits}</strong>{' '}
                results
            </div>
            <div className="flex space-x-4">
                <button
                    disabled={prevDisabled}
                    className={`
                        border bg-white markup-button-sm rounded-lg px-4 py-2
                        ${
                            prevDisabled
                                ? 'disabled cursor-not-allowed border-gray-2 text-gray-2'
                                : 'border-gray-3 text-gray-3'
                        }
                    `}
                    onClick={() => refine(currentRefinement - 1)}
                >
                    Previous
                </button>

                <button
                    disabled={nextDisabled}
                    className={`
                        border bg-white markup-button-sm rounded-lg px-4 py-2
                        ${
                            nextDisabled
                                ? 'disabled cursor-not-allowed border-gray-2 text-gray-2'
                                : 'border-gray-3 text-gray-3'
                        }
                    `}
                    onClick={() => refine(currentRefinement + 1)}
                >
                    Next
                </button>
            </div>
        </nav>
    );
}

export default connectStats(connectPagination(Pagination));
