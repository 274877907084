import Alpine from 'alpinejs';

Alpine.data('zoomFullScreen', () => ({
    fullScreen: false,
    init() {},
    toggleFullScreen() {
        this.fullScreen = !this.fullScreen;

        const iframeContainer = document.getElementById('zoomIframeContainer');
        if (this.fullScreen) {
            document.body.classList.add('overflow-hidden');
            iframeContainer!.classList.add('zoom-fullscreen');
        } else {
            document.body.classList.remove('overflow-hidden');
            iframeContainer!.classList.remove('zoom-fullscreen');
        }
    },
}));
