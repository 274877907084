import React from 'react';

type Props = {
    name?: string;
    id?: string;
    placeholder?: string;
    required?: boolean;
    hasError?: boolean;
    type?: string;
    value: number | string | null;
    className?: string;
    onChange: (value: string) => void;
};

export default function TextInput({
    name,
    id = name,
    placeholder,
    required,
    type = 'text',
    hasError,
    className,
    value,
    onChange,
}: Props) {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
    }

    return (
        <input
            id={id}
            name={name}
            className={`form-input ${className} ${hasError ? 'form-input-error' : ''}`}
            type={type}
            placeholder={placeholder}
            required={required}
            value={value || ''}
            onChange={handleChange}
        />
    );
}
