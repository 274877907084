const mobileNavigationContainer = document.querySelector('.nav-mobile-container') as HTMLElement | null;
const burger = mobileNavigationContainer?.querySelector('.burger') as HTMLElement | null;
const mobileMenu = document.querySelector('.mobile-menu') as HTMLElement | null;

if (burger) {
    burger.addEventListener('click', (event) => {
        event.preventDefault();

        toggleMobileNavigation();
    });
}

if (mobileMenu) {
    mobileMenu.addEventListener('click', (event) => {
        const element = event.target as HTMLElement;

        if ('BUTTON' === element.tagName && element.classList.contains('mobile-submenu-toggle')) {
            event.preventDefault();

            const parentItem = element.closest('.mobile-menu-item');

            mobileMenu.querySelectorAll('.mobile-menu-item').forEach((item) => {
                if (item.classList.contains('is-open') && item !== parentItem) {
                    item.classList.remove('is-open');
                }
            });

            if (parentItem) {
                parentItem.classList.toggle('is-open');
            }
        }
    });
}

function toggleMobileNavigation() {
    const body = document.querySelector('body');

    if (!body) {
        return;
    }

    if (body.classList.contains('search-is-open')) {
        body.classList.remove('search-is-open');
    }

    body.classList.toggle('nav-mobile-is-open');

    document.querySelectorAll('.mobile-menu-item').forEach((item) => {
        if (item && item.classList.contains('is-open')) {
            item.classList.remove('is-open');
        }
    });
}
