import React from 'react';
import { DateTime as Luxon } from 'luxon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'front/app/catalog/components/SearchCatalog';

export type Values = {
    start: number | null | undefined;
    end: number | null | undefined;
};

type Props = {
    name?: string;
    id?: string;
    required?: boolean;
    hasError?: boolean;
    start: number | null | undefined;
    end: number | null | undefined;
    onChange: ({ start, end }: Values) => void;
};

function isMobile() {
    return window.innerWidth < 768;
}

export default function DateRangeInput({ name, id, required, hasError, start, end, onChange }: Props) {
    const datePicker = (picker: 'start' | 'end', placeholder: string) => (
        <DatePicker
            portalId="datepicker-target"
            name={name}
            id={id + '-' + picker}
            placeholderText={placeholder}
            required={required}
            dateFormat="MM-dd-yyyy"
            className={`form-input ${hasError ? 'border-error-2' : ''} placeholder-gray-2`}
            selected={parseTimestamp(picker === 'start' ? start : end)}
            onChange={(date) =>
                picker === 'start'
                    ? onChange({ start: formatDate(date as Date | null), end })
                    : onChange({ start, end: formatDate(date as Date | null) })
            }
            minDate={picker === 'end' ? parseTimestamp(start) : undefined}
            startDate={parseTimestamp(start)}
            endDate={parseTimestamp(end)}
            selectsStart={picker === 'start'}
            selectsEnd={picker === 'end'}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable={Boolean(picker === 'start' ? start : end)}
            withPortal={isMobile()}
            /* Prevent keyboard on mobile */
            onFocus={(e) => {
                if (isMobile()) e.target.readOnly = true;
            }}
        />
    );

    return (
        <>
            <Field label="Date Range">
                <div className="grid grid-cols-2 gap-2">
                    <div className="form-input-has-icon">
                        <div className="form-input-icon">
                            <i className="icon-filters-calendar text-primary-2" />
                        </div>
                        {datePicker('start', 'Start Date')}
                    </div>
                    <div className="form-input-has-icon">
                        <div className="form-input-icon">
                            <i className="icon-filters-calendar text-primary-2" />
                        </div>
                        {datePicker('end', 'End Date')}
                    </div>
                </div>
            </Field>
        </>
    );
}

function parseTimestamp(timestamp: number | null | undefined): Date | undefined {
    if (!timestamp) {
        return undefined;
    }

    return Luxon.fromSeconds(timestamp).toJSDate();
}

function formatDate(date: Date | null): number | undefined {
    if (!date) {
        return undefined;
    }

    return Luxon.fromJSDate(date).toSeconds();
}
