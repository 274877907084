import React, { useState } from 'react';
import Axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import type { Options } from 'front/ui/forms/types';
import { uniqBy } from 'lodash';
import customStyles from 'front/ui/forms/inputs/SelectStyles';
import useDebouncedCallback from 'front/hooks/useDebouncedCallback';

type Props<T> = {
    name?: string;
    id?: string;
    required?: boolean;
    inputValue?: string;
    value: Array<T>;
    options: Options<T>;
    endpoint?: string;
    autoFocus?: boolean;
    onChange: (value: Array<T>) => void;
    placeholder?: string;
};

export default function MultiSelectInput<T = string>({
    name,
    id,
    value,
    options,
    endpoint,
    autoFocus,
    onChange,
    placeholder,
}: Props<T>) {
    const [usedOptions, setUsedOptions] = useState<Options<T>>([]);

    function addUsedOptions(options: Options<T>) {
        setUsedOptions((usedOptions) => {
            return uniqBy([...usedOptions, ...options], (option) => option.value);
        });
    }

    const availableOptions = uniqBy([...options, ...usedOptions], (option) => option.value);

    function handleChange(selectedOptions: Options<T> | null) {
        addUsedOptions(selectedOptions || []);

        onChange((selectedOptions || []).map((option) => option.value));
    }

    const selectedOptions = availableOptions.filter((option) => value.includes(option.value));

    const loadOptions = useDebouncedCallback(
        (inputValue: string, callback) => {
            if (!endpoint) {
                throw new Error('Unable to load options without an endpoint');
            }

            Axios.get(`${endpoint}?filter[search]=${inputValue}`).then((response) => callback(response.data));
        },
        [endpoint],
        250,
    );

    if (!endpoint) {
        return (
            <Select
                name={name}
                inputId={id}
                value={selectedOptions}
                options={options}
                placeholder={placeholder}
                onChange={handleChange as any}
                styles={customStyles}
                autoFocus={autoFocus}
                isMulti
                menuPortalTarget={document.body}
            />
        );
    }

    return (
        <AsyncSelect
            name={name}
            inputId={id}
            value={selectedOptions}
            onChange={handleChange as any}
            styles={customStyles}
            placeholder={placeholder}
            noOptionsMessage={({ inputValue }) => (inputValue ? 'No search results' : 'Start typing to search…')}
            autoFocus={autoFocus}
            defaultOptions={options}
            loadOptions={loadOptions}
            isMulti
            menuPortalTarget={document.body}
        />
    );
}
