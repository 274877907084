import React from 'react';
import { $$ } from 'front/util';
import { render } from 'react-dom';
import SearchCatalog from './components/SearchCatalog';

$$('[data-catalog]').forEach((catalog) => {
    render(
        <SearchCatalog
            displayName={catalog.dataset.displayName!}
            indexName={catalog.dataset.catalog!}
            tabs={JSON.parse(catalog.dataset.tabs!)}
            colors={JSON.parse(catalog.dataset.colors!)}
            timezone={catalog.dataset.timezone!}
            creditHourLabel={catalog.dataset.creditHourLabel!}
            calendarLogo={catalog.dataset.calendarLogo!}
            displayCreditHourType={Boolean(catalog.dataset.displayCreditHourType!)}
        />,
        catalog,
    );
});
