import Alpine from 'alpinejs';
import BundleDiscountData = App.Context.Product.Data.Bundle.BundleDiscountData;

function sumBy(map: Map<string, { basePrice: number; memberPrice: number }>, key: 'basePrice' | 'memberPrice') {
    return Array.from(map.values()).reduce((price, item) => price + item[key], 0);
}

const currencyFormatter = (currency: number | null) => {
    if (currency === null) {
        return null;
    }

    const sansDec = (currency / 100).toFixed(2);
    const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formatted.replace('.00', '');
};

Alpine.data('agenda', () => ({
    expandedDays: [] as Array<number>,
    selectedSessions: {} as Record<string, string>,
    selectedType: 'any',
    onlyOnline: false,

    discounts: [] as Array<BundleDiscountData>,
    customBundle: new Map() as Map<string, { basePrice: number; memberPrice: number }>,
    firstSelectedDeliveryFormat: '' as 'online' | 'in_person' | '',

    get _basePrice() {
        return sumBy(this.customBundle, 'basePrice');
    },
    get basePrice() {
        return currencyFormatter(this._basePrice);
    },
    get _memberPrice() {
        return sumBy(this.customBundle, 'memberPrice');
    },
    get memberPrice() {
        return currencyFormatter(this._memberPrice);
    },

    discountedPrice(price: number) {
        const discount = this._discount;
        if (!discount) return null;

        if (discount.type === 'percentage') {
            return currencyFormatter(price * (1 - discount.amount / 10000));
        }

        if (discount.type === 'fixed') {
            return currencyFormatter(price - discount.amount);
        }
    },

    get discountedBasePrice() {
        return this.discountedPrice(this._basePrice);
    },
    get discountedMemberPrice() {
        return this.discountedPrice(this._memberPrice);
    },

    get _discount() {
        return this.discounts.find((discount) => this.customBundle.size >= discount.minimum_amount_of_items);
    },

    get _nextDiscount() {
        return [...this.discounts]
            .reverse() // this.discounts.toReversed() when toReversed has landed in used ts version
            .find((discount: BundleDiscountData) => this.customBundle.size < discount.minimum_amount_of_items);
    },

    get discount() {
        const discount = this._discount;
        if (!discount) return null;

        if (discount.type === 'percentage') {
            return `${discount.amount / 100}%`;
        } else if (discount.type === 'fixed') {
            return `$${currencyFormatter(discount.amount)}`;
        }
    },

    get nextDiscount() {
        const discount = this._nextDiscount;
        if (!discount) return null;
        let discountText;

        if (discount.type === 'percentage') {
            discountText = `${discount.amount / 100}%`;
        } else if (discount.type === 'fixed') {
            discountText = `$${currencyFormatter(discount.amount)}`;
        }

        return {
            text: discountText,
            items_needed: discount.minimum_amount_of_items - this.customBundle.size,
        };
    },

    updateBundle(included: boolean, id: string, prices: { basePrice: number; memberPrice: number }) {
        if (included) {
            this.customBundle.set(id, prices);
            this.selectedSessions = {
                ...this.selectedSessions,
                [`products[${id}][included]`]: id,
            };
        } else {
            this.customBundle.delete(id);
            delete this.selectedSessions[`products[${id}][included]`];
        }
        this.customBundle = new Map(this.customBundle);
    },

    updateAgenda(slot: string, id: string, prices: { basePrice: number; memberPrice: number }) {
        this.customBundle.set(slot, prices);
        this.selectedSessions[slot] = id;
        this.customBundle = new Map(this.customBundle);
    },

    clearSelected(name: string) {
        delete this.selectedSessions[name];
        this.customBundle.delete(name);
        this.customBundle = new Map(this.customBundle);
    },

    expandDay(dayIndex: number) {
        this.expandedDays.includes(dayIndex)
            ? (this.expandedDays = this.expandedDays.filter((id: number) => id != dayIndex))
            : this.expandedDays.push(dayIndex);
    },

    init() {
        this.selectedType = this.$el?.dataset.initialSelectedType || 'any';
        this.discounts =
            JSON.parse(this.$el?.dataset?.discounts || '[]')
                ?.map((discount: any) => ({
                    type: discount.type,
                    amount: discount.amount,
                    minimum_amount_of_items: discount.minimum_amount_of_items,
                }))
                .sort((a: any, b: any) => b.minimum_amount_of_items - a.minimum_amount_of_items) || [];

        this.$watch('selectedType', () => {
            this.selectedSessions = {};

            const checkboxes = document.querySelectorAll('[name^=products]');
            [...checkboxes].map((el) => {
                /* @ts-expect-error Todo */
                el.checked = this.selectall;
            });

            this.customBundle = new Map();
        });

        this.$watch('onlyOnline', () => {
            this.selectedType = this.onlyOnline ? 'online' : 'any';
        });

        this.expandedDays = Array.from(this.$el?.querySelectorAll('.agenda-day-default-open') ?? []).map((el) => {
            return +(el.getAttribute('data-index') ?? 0);
        });
    },
}));
