import Alpine from 'alpinejs';
import type { DailyCall, DailyParticipant } from '@daily-co/daily-js';
import DailyIframe from '@daily-co/daily-js';
import { setBrowserFullscreen } from 'front/util';
import { postViewerEvent, registerViewerEvents } from 'front/app/eLearning/viewerEvents';

/*
@TODO viewer events:
user_sent_chat
user_completed_event_survey
user_failed_completion_requirements
*/

const expandIcon =
    '<svg class="w-6 h-6" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="expand" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z" class="text-black"></path></svg>';
const compressIcon =
    '<svg class="w-6 h-6" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="compress" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v84H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-84h84c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z" class="text-black"></path></svg>';

Alpine.data('daily', () => ({
    callFrame: null as DailyCall | null,
    isFullscreen: false,
    lastIsMicEnabled: false,
    lastIsCameraEnabled: false,
    participantUpdatedEventCount: 0,

    init() {
        const callFrame = DailyIframe.createFrame(this.$el!, {
            iframeStyle: { width: '100%', height: '100%' },
            showLeaveButton: false,
            showFullscreenButton: false,
            activeSpeakerMode: true,
        });

        callFrame.setPlayNewParticipantSound(false);
        callFrame.load({ url: this.$el!.dataset.url });
        callFrame.join({
            token: this.$el!.dataset.token,
        });

        this.callFrame = callFrame;

        registerViewerEvents(this.$el!.dataset.eventSessionUuid!);
        this.registerDailyEvents();

        if (!this.$el!.dataset.noFullscreen) {
            this.createFullScreenButton();
        }
    },

    registerDailyEvents() {
        this.callFrame
            ?.on('joined-meeting', () => this.postViewerEvent('user_video_stream_started_manually'))
            .on('left-meeting', () => this.postViewerEvent('user_left'))
            .on('camera-error', () => this.postViewerEvent('user_video_stream_camera_error'))
            .on('error', () => this.postViewerEvent('user_video_stream_start_error'))
            .on('local-screen-share-started', () => this.postViewerEvent('user_video_stream_screen_sharing_enabled'))
            .on('local-screen-share-stopped', () => this.postViewerEvent('user_video_stream_screen_sharing_disabled'))
            .on('participant-updated', (event) => this.handleParticipantUpdated(event?.participant));
    },

    handleParticipantUpdated(participant?: DailyParticipant) {
        if (!participant) {
            return;
        }

        // When the Daily widget loads, it emits two rogue participant-updated events with audio/video set to true, and
        // then immediately to false. To avoid excess viewer events, we'll ignore the first two events Daily emits.
        this.participantUpdatedEventCount++;
        if (this.participantUpdatedEventCount <= 2) {
            return;
        }

        if (this.lastIsMicEnabled !== participant.audio) {
            this.postViewerEvent(
                participant.audio ? 'user_video_stream_mic_enabled' : 'user_video_stream_mic_disabled',
            );
            this.lastIsMicEnabled = participant.audio;
        }

        if (this.lastIsCameraEnabled !== participant.video) {
            this.postViewerEvent(
                participant.video ? 'user_video_stream_camera_enabled' : 'user_video_stream_camera_disabled',
            );
            this.lastIsCameraEnabled = participant.video;
        }
    },

    createFullScreenButton() {
        const fullscreenButton = document.createElement('button');
        fullscreenButton.innerHTML = expandIcon;
        fullscreenButton.classList.add(
            'absolute',
            'bottom-0',
            'right-0',
            'p-2',
            'mr-2',
            'mb-3',
            'rounded',
            /* 'hover:bg-gray-50',
            'transition-all',
            'duration-150' */
        );
        this.$el!.appendChild(fullscreenButton);

        fullscreenButton.addEventListener('click', () => {
            const nextFullscreen = !this.isFullscreen;

            const reset = () => {
                this.isFullscreen = false;
                this.$el!.classList.remove('h-screen', 'w-screen', 'fixed', 'z-daily-fullscreen');
                this.$el!.classList.toggle('absolute');
                document.onfullscreenchange = null;
                fullscreenButton.innerHTML = expandIcon;

                document.body.classList.remove('overflow-hidden', 'daily-fullscreen');

                this.postViewerEvent('user_video_stream_exited_fullscreen');
            };

            setBrowserFullscreen(nextFullscreen);

            if (nextFullscreen) {
                this.$el!.classList.add('h-screen', 'w-screen', 'fixed', 'z-daily-fullscreen');
                this.$el!.classList.toggle('absolute');
                document.body.classList.add('overflow-hidden', 'daily-fullscreen');
                document.onfullscreenchange = () => !document.fullscreenElement && reset();
                fullscreenButton.innerHTML = compressIcon;

                this.postViewerEvent('user_video_stream_entered_fullscreen');
            } else {
                reset();
            }

            this.isFullscreen = nextFullscreen;
        });
    },

    postViewerEvent(event: App.Context.ELearning.Enums.ViewerEventType) {
        postViewerEvent(event, this.$el!.dataset.eventSessionUuid!);
    },
}));
