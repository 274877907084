import Alpine from 'alpinejs';
import TomSelect from 'tom-select';
import type { TomLoadCallback } from 'tom-select/dist/types/types';

Alpine.data('select', () => ({
    init() {
        const options = this.$el?.dataset.options ? JSON.parse(this.$el?.dataset.options) : [];
        const multi = Boolean(this.$el?.dataset.multi);
        const endpoint = this.$el?.dataset.endpoint;
        const creatable = Boolean(this.$el?.dataset.creatable);

        // setTimeout is needed to get initial value from Alpine
        setTimeout(() => {
            new TomSelect(this.$el?.querySelector('input')!, {
                allowEmptyOption: true,
                maxItems: multi ? undefined : 1,
                create: creatable,
                valueField: 'value',
                labelField: 'label',
                searchField: ['label'],
                options,
                createFilter: (input: string) => input.length >= 2,
                load: endpoint
                    ? async (query: string, callback: TomLoadCallback) => {
                          const url = `${endpoint}?filter[search]=${query}&optionsQuery=true`;

                          try {
                              const response = await fetch(url);
                              callback(await response.json(), []);
                          } catch (error) {
                              callback([], []);
                          }
                      }
                    : undefined,
            });
        });
    },
}));

/* USAGE
<x-alpine-select
    label="Users"
    name="users"

    options='[{"label":"hi","value":"hi"}]' // optional
    endpoint="" // optional
    creatable // optional
    multi // optional (default = single)
/>
*/
