import Swiper, {
    Navigation,
    Pagination,
    Autoplay,
    EffectFade,
    EffectCards,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    EffectCreative,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-creative';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-flip';

Swiper.use([
    Navigation,
    Pagination,
    Autoplay,
    EffectFade,
    EffectCards,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    EffectCreative,
]);

document.querySelectorAll('.swiper').forEach((el) => {
    const config = JSON.parse((el as HTMLElement).dataset?.config || '');

    new Swiper(el as HTMLElement, {
        pagination: config.showPagination
            ? {
                  el: '.swiper-pagination',
                  clickable: true,
                  renderBullet: (index, className) => {
                      return `<span class="${className}">${index + 1}</span>`;
                  },
              }
            : false,
        navigation: config.showNavigation,
        autoHeight: config.autoHeight,
        autoplay: config.autoplayDelay
            ? {
                  delay: config.autoplayDelay || 0,
              }
            : undefined,
        effect: config.transitionEffect,

        fadeEffect: { crossFade: true },
        loop: true,
        centeredSlides: true,
        createElements: true,
    });
});
