import React from 'react';

type Props = {
    is?: 'secondary' | 'primary' | 'cancel' | 'link' | 'danger' | 'close' | 'remove';
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
    size?: 'sm' | 'md' | 'lg';
};

export default function Button({
    is = 'secondary',
    type = 'button',
    disabled = false,
    onClick,
    children,
    size = 'md',
}: Props) {
    const roundedButtonStyles = 'uppercase tracking-wider rounded-full px-4 py-1';

    const circularSize = {
        sm: 'min-w-6 h-6 text-base',
        md: 'min-w-8 h-8 text-xl ',
        lg: 'min-w-10 h-10 text-2xl ',
    }[size];

    const circularButtonStyles = `${circularSize} flex items-center justify-center rounded-full`;

    const typeClassName = {
        secondary: `${roundedButtonStyles} bg-secondary-3 hover:bg-opacity-75 text-white`,
        primary: `${roundedButtonStyles} bg-primary-2 hover:bg-primary-3 text-white`,
        cancel: `${roundedButtonStyles} bg-neutral-3 hover:bg-neutral-2 text-white`,
        danger: `${roundedButtonStyles} bg-error-2 hover:bg-error-3 text-white`,
        link: 'text-10 text-accent-2 hover:text-accent-3 font-semibold tracking-wide underline uppercase',
        close: `${circularButtonStyles} bg-transparent hover:bg-gray-100 text-gray-500 hover:text-gray-500`,
        remove: `${circularButtonStyles} bg-transparent hover:bg-red-100 text-gray-500 hover:text-red-500`,
    }[is];

    return (
        <button
            type={type}
            className={`${typeClassName} ${
                disabled ? 'opacity-50' : ''
            } outline-none focus:ring-0 focus:border-indigo-500`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
