import type { DebouncedFunc } from 'lodash';
import { debounce } from 'lodash';
import { useCallback } from 'react';

export default function useDebouncedCallback<T extends (...args: Array<any>) => any>(
    callback: T,
    deps: Array<any>,
    wait: number,
): DebouncedFunc<T> {
    return useCallback(debounce(callback, wait, { leading: true }), deps);
}
