import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import Alpine from 'alpinejs';
import { postViewerEvent, registerViewerEvents } from 'front/app/eLearning/viewerEvents';
import { setBrowserFullscreen } from 'front/util';
import type { VideoJsPlayer } from 'video.js';

/*
@TODO viewer events:
user_completed_event_survey
user_failed_completion_requirements
*/

Alpine.data('videoStream', () => ({
    isFullscreen: false,
    player: null as null | (VideoJsPlayer & { el_: HTMLVideoElement }),
    lastIsMuted: true,

    getDataAttribute(attributeName: string, defaultValue?: string) {
        const value = this.$el!.dataset[attributeName];

        if (!value) {
            if (defaultValue) {
                return defaultValue;
            }

            throw new Error(`Required attribute ${attributeName} not found on the component.`);
        }

        return value;
    },

    init() {
        const videoElement = this.$el!.querySelector('video');
        if (!videoElement) {
            throw new Error('No <video> element found in the children of the Alpine video component');
        }

        const { muxPlaybackId, hlsUrl } = this.$el!.dataset;

        if (!muxPlaybackId && !hlsUrl) {
            throw new Error('No data-mux-playback-id or data-hls-url attribute found on the Alpine video component');
        }
        if (muxPlaybackId && hlsUrl) {
            throw new Error(
                'Both data-mux-playback-id or data-hls-url attribute found on the Alpine video component, pick one',
            );
        }

        registerViewerEvents(this.$el!.dataset.eventSessionUuid!);

        // Initialize videojs player
        this.player = videojs(videoElement, {
            plugins: {
                mux: {
                    data: { env_key: this.getDataAttribute('muxKey'), video_title: this.getDataAttribute('title', '') },
                },
                httpSourceSelector: {},
            },
            timelineHoverPreviews: false,
            poster: muxPlaybackId ? `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg` : undefined,
            userActions: {
                doubleClick: () => this.toggleFullscreen(),
                fullscreenKey: () => this.toggleFullscreen(),
                playPauseKey: () => false,
            },
            controlBar: {
                fullscreenToggle: true,
                volumePanel: true,
                playToggle: false,
                currentTimeDisplay: false,
                timeDivider: false,
                durationDisplay: false,
                progressControl: false,
                liveDisplay: true,
                seekToLive: false,
                remainingTimeDisplay: false,
                customControlSpacer: false,
                playbackRateMenuButton: false,
                chaptersButton: false,
                descriptionsButton: false,
                subsCapsButton: false,
                subtitlesButton: true,
                audioTrackButton: false,
                pictureInPictureToggle: false,
            },
            // @ts-expect-error Todo
            smoothQualityChange: true,
            handleManifestRedirects: true,
        });

        if (muxPlaybackId) {
            this.player!.src({ type: 'video/mux', src: muxPlaybackId });
        } else if (hlsUrl) {
            this.player!.src(hlsUrl);
        }

        const playWithoutError = () => this.player!.play()?.catch((_) => {});
        this.player!.play()
            ?.then(() => {
                // Don't allow pausing through hardware controls
                this.player!.on('pause', playWithoutError);

                this.postViewerEvent('user_video_stream_started_automatically');
            })
            .catch(() => {
                // Autoplaying is disabled in some browsers
                this.player!.on('play', () => {
                    this.player!.on('pause', playWithoutError);
                    this.postViewerEvent('user_video_stream_started_manually');
                });
                this.player!.one('play', () => this.syncVideoTime());
            });

        this.player.on('volumechange', () => {
            const isMuted = this.player?.muted() || this.player?.volume() === 0;

            if (isMuted !== this.lastIsMuted) {
                this.postViewerEvent(isMuted ? 'user_video_stream_muted' : 'user_video_stream_un_muted');
                this.lastIsMuted = isMuted;
            }
        });

        if (this.getDataAttribute('streamType') === 'videoStream') {
            // Make sure we're always live within 2 seconds by syncing time every 5 seconds
            setInterval(() => this.syncVideoTime(), 5000);
            this.syncVideoTime();
        }

        this.createFullScreenButton();
    },

    createFullScreenButton() {
        const oldFullscreenButton = document.getElementsByClassName('vjs-fullscreen-control')?.[0];
        const newFullscreenButton = oldFullscreenButton.cloneNode(true);
        oldFullscreenButton.parentNode?.replaceChild(newFullscreenButton, oldFullscreenButton);
        newFullscreenButton.addEventListener('click', () => this.toggleFullscreen());
    },

    syncVideoTime() {
        const startTime = new Date(this.getDataAttribute('startTime')).getTime();

        const syncedTime = (Date.now() - startTime) / 1000;

        const playerTime = this.player!.currentTime();

        if (playerTime < syncedTime - 2 || playerTime > syncedTime + 2) {
            this.player!.currentTime(syncedTime);
        }
    },

    toggleFullscreen() {
        const nextFullscreen = !this.isFullscreen;

        const reset = () => {
            this.isFullscreen = false;
            this.player!.el_.classList.remove('h-screen', 'w-screen', 'fixed', 'z-50', 'inset-0');
            this.player!.el_.classList.add('vjs-16-9');
            document.body.classList.remove('overflow-hidden');
            document.onfullscreenchange = null;

            this.postViewerEvent('user_video_stream_exited_fullscreen');
        };

        setBrowserFullscreen(nextFullscreen);

        if (nextFullscreen) {
            this.player!.el_.classList.add('h-screen', 'w-screen', 'fixed', 'z-50', 'inset-0');
            this.player!.el_.classList.remove('vjs-16-9');
            document.body.classList.add('overflow-hidden');

            document.onfullscreenchange = () => !document.fullscreenElement && reset();

            this.postViewerEvent('user_video_stream_entered_fullscreen');
        } else {
            reset();
        }

        this.isFullscreen = nextFullscreen;
    },

    postViewerEvent(event: App.Context.ELearning.Enums.ViewerEventType) {
        postViewerEvent(event, this.$el!.dataset.eventSessionUuid!);
    },
}));
