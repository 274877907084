// Fix for scrolling not working for next/back buttons in the wizard
const wizardElement = document.querySelector('.sail-wizard');
if (wizardElement instanceof HTMLElement) {
    wizardElement.addEventListener('click', (event) => {
        const targetElement = event.target;
        if (
            targetElement instanceof HTMLElement &&
            targetElement.tagName === 'BUTTON' &&
            (targetElement.innerText === 'Next' || targetElement.innerText === 'Back')
        ) {
            wizardElement.scrollIntoView();
        }
    });
}
