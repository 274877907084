import Alpine from 'alpinejs';
import * as Sentry from '@sentry/browser';
import { postViewerEvent, registerViewerEvents } from 'front/app/eLearning/viewerEvents';

Alpine.data('zoom', () => ({
    init() {
        const ZoomMtg = window.ZoomMtg;

        if (!ZoomMtg) {
            return;
        }

        const sessionUuid = this.$el!.dataset.eventSessionUuid!;

        registerViewerEvents(sessionUuid);

        // @ts-expect-error Not sure how to fix?
        const sdkVersion = ZoomMtg.getWebSDKVersion()[0] ?? this.getDataAttribute('zoomSdkVersion');
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        // loads language files, also passes any error messages to the ui
        ZoomMtg.i18n.load('en-US');
        ZoomMtg.i18n.reload('en-US');

        ZoomMtg.setZoomJSLib('https://source.zoom.us/' + sdkVersion + '/lib', '/av');

        ZoomMtg.init({
            leaveUrl: this.getDataAttribute('leaveUrl', ''),
            disableInvite: true,
            // We want to disable this but it's broken. Refreshing after initially joining gets stuck on "Joining Meeting".
            // disablePreview: true,
            meetingInfo: ['topic', 'host', 'telPwd', 'dc', 'enctype', 'report'],
            enableHD: true,
            enableFullHD: true,
            isSupportPolling: this.getDataAttribute('disablePolls', 'false') === 'false',
            debug: window.Sail.environment.environment === 'local',
            patchJsMedia: true,
            disablePreview: true,
            disablePictureInPicture: true,
            leaveOnPageUnload: true,
            success: () => {
                ZoomMtg.join({
                    sdkKey: this.getDataAttribute('apiKey'),
                    signature: this.getDataAttribute('signature'),
                    meetingNumber: this.getDataAttribute('meetingNumber'),
                    passWord: this.getDataAttribute('password'),
                    userName: this.getDataAttribute('userName', ''),
                    userEmail: this.getDataAttribute('userEmail', ''),
                    customerKey: this.getDataAttribute('userUserFacingId', ''),
                    success: () => {
                        postViewerEvent('user_video_stream_started_automatically', sessionUuid);
                    },
                    error: (error: object) => {
                        Sentry.captureException(error);
                        postViewerEvent('user_video_stream_start_error', sessionUuid);
                        console.log(error);
                    },
                });
            },
            error: (error: object) => {
                Sentry.captureException(error);
                postViewerEvent('user_video_stream_start_error', sessionUuid);
                console.log(error);
            },
        });
    },

    getDataAttribute(attributeName: string, defaultValue?: string): string {
        const value = this.$el!.dataset[attributeName] ?? '';

        if (!value) {
            if (defaultValue !== undefined) {
                return defaultValue ?? '';
            }

            throw new Error(`Required attribute ${attributeName} not found on the component.`);
        }

        return value;
    },
}));
