import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import Button from 'front/ui/forms/inputs/Button';

type CurrentRefinements = {
    items: Array<{}>;
    refine: (items: Array<{}>) => {};
};

function ClearRefinements({ items, refine }: CurrentRefinements) {
    return (
        <div className={!items.length ? 'opacity-0 pointer-events-none' : ''}>
            <Button is="link" onClick={() => refine(items)} disabled={!items.length}>
                Reset filters
            </Button>
        </div>
    );
}

// @ts-expect-error Todo
export default connectCurrentRefinements(ClearRefinements);
