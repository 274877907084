// @ts-nocheck
import React from 'react';

export const LarutaHit = ({ hitName, hitDataType, hitDetails, hitPermalink }) => {
    return (
        <a href={hitPermalink} className="block group" target="_new">
            <article>
                <header>
                    <h2 className="event-card-delivery">{hitDataType}</h2>
                </header>
                <h1 className="event-card-title group-hover:underline">{hitName}</h1>
                <footer>
                    <ul className="mt-2 event-card-props">
                        {hitDetails
                            // remove undefined & empty strings
                            .filter((detail) => {
                                return typeof detail === 'string' && detail !== '';
                            })
                            .map((detail) => (
                                <li key={detail.toString()}>{detail}</li>
                            ))}
                    </ul>
                </footer>
            </article>
            <hr className="event-card-hr" />
        </a>
    );
};
