import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { DateTime as Luxon } from 'luxon';
import type { Refinement, RefinementValue, CurrentRefinementsProvided } from 'react-instantsearch-core';
import type { RangeRefinement } from 'front/app/catalog/types';

type LabelProps = {
    item: {
        label: string;
        value: RefinementValue;
    };
} & (
    | {
          attribute: string;
          currentRefinement: string | string[] | {};
      }
    | {
          attribute: 'tabs';
          currentRefinement: string;
      }
    | {
          attribute: 'start' | 'end';
          currentRefinement: RangeRefinement;
      }
);

type BadgeProps = LabelProps & {
    refine: (refinement: RefinementValue | RefinementValue[] | Refinement[]) => void;
};

function formatRefinementLabel({ item, attribute, currentRefinement }: LabelProps): string {
    const currentTab = currentRefinement as string;
    if (attribute === 'tabs') {
        return currentTab;
    }

    if (attribute !== 'start' && attribute !== 'end') {
        return item.label;
    }

    const currentRange = currentRefinement as RangeRefinement;

    if (!currentRange.min && !currentRange.max) {
        return '';
    }

    if (!currentRange.min) {
        return '< ' + Luxon.fromSeconds(currentRange.max!).toFormat('MM-dd-yyyy');
    }

    if (!currentRange.max) {
        return '> ' + Luxon.fromSeconds(currentRange.min).toFormat('MM-dd-yyyy');
    }

    return (
        Luxon.fromSeconds(currentRange.min).toFormat('MM-dd-yyyy') +
        ' - ' +
        Luxon.fromSeconds(currentRange.max).toFormat('MM-dd-yyyy')
    );
}

function Badge({ item, refine, attribute, currentRefinement }: BadgeProps) {
    return (
        <span className="min-w-0 flex items-center gap-2 border-primary-2 border rounded-full pl-2 pr-1.5">
            <div className="min-w-0 truncate markup-body-sm uppercase text-primary-2">
                {formatRefinementLabel({ item, attribute, currentRefinement })}
            </div>
            <a
                className="inline-block text-primary-2 hover:text-primary-3 cursor-pointer"
                onClick={(event) => {
                    event.preventDefault();
                    refine(item.value);
                }}
            >
                &times;
            </a>
        </span>
    );
}

function CurrentRefinements({ items, refine, display }: CurrentRefinementsProvided & { display: string }) {
    return (
        <div className="gap-x-4 gap-y-1 flex flex-wrap">
            {items
                .filter((item) => {
                    if (display === 'list') {
                        return true;
                    }

                    return !['start', 'end'].includes(item.attribute);
                })
                .map((item) => (
                    <React.Fragment key={item.label}>
                        {item.items ? (
                            <>
                                {item.items.map((nested) => (
                                    <Badge
                                        key={nested.label}
                                        item={nested}
                                        attribute={item.attribute}
                                        currentRefinement={item.currentRefinement}
                                        refine={refine}
                                    />
                                ))}
                            </>
                        ) : (
                            <Badge
                                key={item.label}
                                item={item}
                                attribute={item.attribute}
                                currentRefinement={item.currentRefinement}
                                refine={refine}
                            />
                        )}
                    </React.Fragment>
                ))}
        </div>
    );
}

export default connectCurrentRefinements(CurrentRefinements);
