import * as Sentry from '@sentry/browser';
import { $, ajax, loadScript } from 'front/util';

type OrderStatus = App.Context.Order.States.OrderStatus.OrderStatus;

let stripeInstance: stripe.Stripe | null = null;

async function getStripe(stripeAccountId?: string): Promise<stripe.Stripe> {
    if (stripeInstance) {
        return stripeInstance;
    }

    await loadScript('https://js.stripe.com/v3/');

    if (!stripeInstance) {
        const options: stripe.StripeOptions = {
            stripeAccount: stripeAccountId,
        };

        stripeInstance = window.Stripe(window.Sail.stripe.publishable_key, options);
    }

    return stripeInstance;
}

class StripePaymentIntent extends HTMLElement {
    stripeAccountId: string | undefined;
    succeedCartUrl: string;
    resetCartUrl: string;
    orderStatus?: OrderStatus;
    form: HTMLFormElement | null = null;
    card: stripe.elements.Element | null = null;
    savePaymentMethod: boolean = true;
    error: string | null = null;
    errorLabel: HTMLElement | null = null;

    constructor() {
        super();

        this.succeedCartUrl = this.getAttribute('succeed-cart-url') || '';
        this.resetCartUrl = this.getAttribute('reset-cart-url') || '';
        this.orderStatus = (this.getAttribute('order-status') as OrderStatus) || null;

        if (!this.succeedCartUrl) {
            throw new Error('No client secret URL provided');
        }

        if (!this.resetCartUrl) {
            throw new Error('No reset cart URL provided');
        }

        const stripeAccountId = this.getAttribute('stripe-account-id');

        if (stripeAccountId) {
            this.stripeAccountId = stripeAccountId;
        }

        this.confirmCardSetupBeforeSubmit = this.confirmCardSetupBeforeSubmit.bind(this);
    }

    async connectedCallback() {
        this.form = this.closest('form');

        if (!this.form) {
            throw new Error('stripe-payment-intent must have a parent form');
        }

        this.errorLabel = document.getElementById('error-label');

        let stripe;
        try {
            stripe = await getStripe(this.stripeAccountId);
        } catch (e) {
            this.errorLabel!.classList.remove('hidden');
            this.errorLabel!.querySelector('p')!.textContent = 'Error loading checkout. Please contact support.';
            document
                .getElementById('checkout-button')!
                .parentElement!.classList.add('pointer-events-none', 'opacity-50');
            return;
        }

        const container = document.createElement('div');
        container.classList.add('form-input', 'py-2');
        this.appendChild(container);

        this.card = stripe.elements().create('card', {
            hidePostalCode: true,
        });
        this.card.mount(container);

        // @todo re-enable this again when we stop forcing saving payment methods
        // this.createSaveCardCheckbox();

        this.form.addEventListener('submit', this.confirmCardSetupBeforeSubmit);
    }

    createSaveCardCheckbox() {
        const saveCardContainer = document.createElement('label');
        saveCardContainer.className = 'flex flex-row items-center mt-2';

        const saveCardCheckbox = document.createElement('input');
        saveCardCheckbox.className = 'form-checkbox mr-2';
        saveCardCheckbox.type = 'checkbox';
        saveCardCheckbox.checked = true;
        saveCardCheckbox.name = 'save_payment_information';
        saveCardCheckbox.addEventListener('change', (e) => (this.savePaymentMethod = (e.target as any).checked));

        const saveCardLabel = document.createElement('span');
        saveCardLabel.innerText = 'Save Payment Information to my Account for Future Purchases';
        saveCardLabel.className = 'text-15 text-gray-3';

        saveCardContainer.appendChild(saveCardCheckbox);
        saveCardContainer.appendChild(saveCardLabel);
        this.appendChild(saveCardContainer);
    }

    disconnectedCallback() {
        this.card?.destroy();

        this.form?.removeEventListener('submit', this.confirmCardSetupBeforeSubmit);
    }

    async confirmCardSetupBeforeSubmit(event: Event) {
        event.preventDefault();

        const onError = (message: string, context: Record<string, unknown> = {}) => {
            Sentry.captureException(new Error(message), { extra: context });

            this.form?.classList.remove('stripe-form-loading');

            if (this.errorLabel) {
                this.errorLabel.classList.remove('hidden');
                this.errorLabel.querySelector('p')!.textContent = message;
            }

            /* @ts-expect-error Todo */
            window.Alpine.$data($('#checkout-form')).submitting = false;

            ajax('POST', this.resetCartUrl, {
                status: this.orderStatus,
            });
        };

        if (!this.form) {
            return onError('No parent form element.');
        }

        this.form.classList.add('stripe-form-loading');

        const stripe = await getStripe();

        const firstName = $('#billing_first_name') as HTMLInputElement;
        const lastName = $('#billing_last_name') as HTMLInputElement;
        const addressLine1 = $('#billing_address_line_1') as HTMLInputElement;
        const addressLine2 = $('#billing_address_line_2') as HTMLInputElement;
        const city = $('#billing_address_city') as HTMLInputElement;
        const zip = $('#billing_address_zip') as HTMLInputElement;
        const createOrderOnly = ($('#create_order_only') as HTMLInputElement | undefined)?.value == '1' || false;

        const paymentMethod = {
            card: this.card!,
            billing_details: {
                name: firstName.value + ' ' + lastName.value,
                address: {
                    line1: addressLine1.value,
                    line2: addressLine2.value,
                    city: city.value,
                    postal_code: zip.value,
                },
            },
        };

        try {
            const data = await ajax('POST', this.form.action, new FormData(this.form));

            if (createOrderOnly) {
                window.location = data.redirect;

                return;
            }

            if (!data.client_secret) {
                throw new InvalidPaymentIntentClientSecretError('No payment intent client secret returned.', data);
            }

            // Setup intent, no payment case.
            if (data.intent_type === 'setup') {
                try {
                    stripe.confirmCardSetup(data.client_secret, {
                        payment_method: paymentMethod,
                    });

                    const succeedData = await ajax('POST', this.succeedCartUrl, { save_payment_method: true });
                    window.location = succeedData.redirect;
                } catch (error: any) {
                    throw new ConfirmCardError(error.message);
                }

                return;
            }

            // Payment intent case.
            try {
                const result = await stripe.confirmCardPayment(data.client_secret, {
                    payment_method: paymentMethod,
                    setup_future_usage: this.savePaymentMethod ? 'off_session' : undefined,
                });

                if (!result.paymentIntent || !result.paymentIntent.payment_method) {
                    return onError(result.error?.message || 'Error during payment, please try again.');
                }
            } catch (error: any) {
                throw new ConfirmCardPaymentError(error.message);
            }

            // Succeed, redirect the user.
            // @todo re-enable this again when we stop forcing saving payment methods
            // const data = await ajax('POST', this.succeedCartUrl, { save_payment_method: this.savePaymentMethod });
            const succeedData = await ajax('POST', this.succeedCartUrl, { save_payment_method: true });
            window.location = succeedData.redirect;
        } catch (error) {
            if (error instanceof InvalidPaymentIntentClientSecretError) {
                return onError('An error occurred while confirming your payment method, please try again.', { error });
            }

            if (error instanceof ConfirmCardError) {
                return onError('An error occurred while trying to save your card, please try again.', { error });
            }

            if (error instanceof ConfirmCardPaymentError) {
                return onError('An error occurred while trying to confirm the payment, please try again.', { error });
            }

            return onError('An error occurred while trying to charge your card, please try again.');
        }
    }
}

class StripeSetupIntent extends HTMLElement {
    card: stripe.elements.Element | null = null;
    clientSecret: string;
    stripe: stripe.Stripe | null = null;
    stripeAccountId: string | undefined;
    errorLabel: HTMLParagraphElement | null = null;
    saveButton: HTMLButtonElement | null = null;

    constructor() {
        super();

        this.clientSecret = this.getAttribute('client-secret') || '';

        if (!this.clientSecret) {
            throw new Error('No client secret provided');
        }

        const stripeAccountId = this.getAttribute('stripe-account-id') || undefined;

        if (stripeAccountId) {
            this.stripeAccountId = stripeAccountId;
        }

        this.saveCard = this.saveCard.bind(this);
        this.onCardChange = this.onCardChange.bind(this);
    }

    async connectedCallback() {
        try {
            this.stripe = await getStripe(this.stripeAccountId);
        } catch (e) {
            this.errorLabel = document.createElement('p');
            this.errorLabel.className = 'text-error-2 mb-6';
            this.appendChild(this.errorLabel);

            this.errorLabel.textContent = 'Error loading checkout. Please contact support.';
            document
                .getElementById('checkout-button')!
                .parentElement!.classList.add('pointer-events-none', 'opacity-50');
            return;
        }

        const container = document.createElement('div');
        container.className = 'form-input py-2 mb-6';
        this.appendChild(container);

        this.errorLabel = document.createElement('p');
        this.errorLabel.className = 'text-error-2 mb-6';
        this.appendChild(this.errorLabel);

        this.saveButton = document.createElement('button');
        this.saveButton.innerText = 'Save Card';
        this.saveButton.className =
            'inline-flex leading-none items-center justify-center transition duration-300 whitespace-nowrap text-white bg-primary-2 hover:bg-primary-3 shadow focus:ring-primary-3 text-14 px-8 py-3 focus:outline-none focus:border-transparent focus:ring-4 rounded-full leading-none tracking-tight font-semibold';
        this.appendChild(this.saveButton);

        this.card = this.stripe.elements().create('card', {
            hidePostalCode: true,
        });
        this.card.mount(container);

        this.card.on('change', this.onCardChange);

        this.saveButton.addEventListener('click', this.saveCard);
    }

    disconnectedCallback() {
        this.card?.destroy();
        this.saveButton?.removeEventListener('click', this.saveCard);
    }

    onCardChange(event?: stripe.elements.ElementChangeResponse) {
        if (!event || !this.errorLabel) {
            return;
        }

        if (event.error) {
            this.errorLabel.textContent = event.error.message || '';
        } else {
            this.errorLabel.textContent = '';
        }
    }

    async saveCard(event: Event) {
        event.preventDefault();

        if (!this.card || !this.stripe) {
            throw new Error('Something went wrong');
        }

        const firstName = $('#billing_first_name') as HTMLInputElement;
        const lastName = $('#billing_last_name') as HTMLInputElement;
        const addressLine1 = $('#billing_address_line_1') as HTMLInputElement;
        const addressLine2 = $('#billing_address_line_2') as HTMLInputElement;
        const city = $('#billing_address_city') as HTMLInputElement;
        const zip = $('#billing_address_zip') as HTMLInputElement;
        const createOrderOnly = ($('#create_order_only') as HTMLInputElement | undefined)?.value == '1' || false;

        if (createOrderOnly) {
            this.closest('form')?.submit();

            return;
        }

        const stripePaymentMethodInput = document.querySelector('input[name="stripe_payment_method_id"]');

        if (!stripePaymentMethodInput) {
            this.stripe
                .confirmCardSetup(this.clientSecret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: firstName.value + ' ' + lastName.value,
                            address: {
                                line1: addressLine1.value,
                                line2: addressLine2.value,
                                city: city.value,
                                postal_code: zip.value,
                            },
                        },
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.errorLabel!.textContent = result.error.message || '';
                    } else {
                        const stripePaymentMethodId = document.createElement('input');
                        stripePaymentMethodId.type = 'hidden';
                        stripePaymentMethodId.name = 'stripe_payment_method_id';
                        stripePaymentMethodId.value = result.setupIntent!.payment_method!;
                        this.appendChild(stripePaymentMethodId);

                        /**
                         * If there is a Stripe Account defined, let's add it to the request
                         * using an hidden input element.
                         */
                        if (this.stripeAccountId) {
                            const stripeAccountIdElement = document.createElement('input');
                            stripeAccountIdElement.type = 'hidden';
                            stripeAccountIdElement.name = 'stripe_account_id';
                            stripeAccountIdElement.value = this.stripeAccountId;
                            this.appendChild(stripeAccountIdElement);
                        }

                        /* @ts-expect-error Todo */
                        if (Livewire) {
                            let additionalPayload = {};

                            if (this.stripeAccountId) {
                                additionalPayload = {
                                    stripe_account_id: this.stripeAccountId,
                                };
                            }

                            /* @ts-expect-error Todo */
                            Livewire.emit('payment-setup', {
                                stripe_payment_method_id: result.setupIntent!.payment_method,
                                ...additionalPayload,
                            });
                        }

                        this.closest('form')?.submit();
                    }
                });
        } else {
            /**
             * The following statements are called in the case where the Stripe card form
             * already succeeded but the billing information form data not.
             * This allows the related Livewire component to continue its own validation
             * without sending new requests to Stripe Intent API because the payment method
             * is already valid.
             */
            const paymentMethodId = stripePaymentMethodInput.getAttribute('value');

            /* @ts-expect-error Todo */
            if (Livewire) {
                let additionalPayload = {};

                if (this.stripeAccountId) {
                    additionalPayload = {
                        stripe_account_id: this.stripeAccountId,
                    };
                }

                /* @ts-expect-error Todo */
                Livewire.emit('payment-setup', {
                    stripe_payment_method_id: paymentMethodId,
                    ...additionalPayload,
                });
            }

            this.closest('form')?.submit();
        }
    }
}

customElements.define('stripe-payment-intent', StripePaymentIntent);
customElements.define('stripe-setup-intent', StripeSetupIntent);

class InvalidPaymentIntentClientSecretError extends Error {
    data: any;

    constructor(message: string, data: any) {
        super(message);

        this.data = data;
    }
}

class ConfirmCardError extends Error {
    constructor(message: string) {
        super(message);
    }
}

class ConfirmCardPaymentError extends Error {
    constructor(message: string) {
        super(message);
    }
}
