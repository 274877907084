// @ts-nocheck
import type { Styles } from 'react-select';

const styles: Styles = {
    control: (provided: {}, state) => ({
        ...provided,
        'width': '100%',
        'minHeight': '2.5rem',
        'borderWidth': 1,
        'borderRadius': '0.25rem',
        'borderColor': state.isFocused ? 'var(--color-primary-2)' : 'var(--color-gray-2)',
        'boxShadow': state.isFocused ? '0px 4px 9px rgba(0, 0, 0, 0.07)' : '0px 1px 2px rgba(0, 0, 0, 0.05)',
        'cursor': 'pointer',
        '&:hover': {
            borderColor: state.isFocused ? 'var(--color-primary-2)' : 'var(--color-gray-2)',
        },
    }),
    indicatorSeparator: (provided: {}) => ({
        ...provided,
        margin: '4px',
        backgroundColor: 'var(--color-gray-1)',
    }),
    clearIndicator: (provided: {}) => ({
        ...provided,
        'color': 'var(--color-gray-2)',
        'padding': '0',
        'fontSize': '9px',
        '&:hover': {
            color: 'var(--color-primary-3)',
        },
    }),
    dropdownIndicator: (provided: {}) => ({
        ...provided,
        'color': 'var(--color-gray-2)',
        'padding': '0 4px 0 0',
        '&:hover': {
            color: 'var(--color-primary-3)',
        },
    }),
    menu: (provided: {}) => ({
        ...provided,
        boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.21)',
        borderWidth: 1,
        borderColor: 'var(--color-gray-2)',
        marginTop: '3px',
    }),
    menuPortal: (provided: {}) => ({ ...provided, zIndex: 1500 }),
    option: (provided: {}, state: { isSelected: false }) => ({
        ...provided,
        'backgroundColor': state.isSelected ? 'var(--color-primary-1)' : '',
        'color': 'var(--color-gray-5)',
        '&:hover': {
            backgroundColor: 'var(--color-primary-1)',
        },
    }),
    placeholder: (provided: {}) => ({
        ...provided,
        color: 'var(--color-gray-2)',
    }),
    noOptionsMessage: (provided: {}) => ({
        ...provided,
        color: 'var(--color-gray-5)',
    }),
    multiValue: (provided: {}) => ({
        ...provided,
        backgroundColor: 'var(--color-primary-1)',
        color: 'var(--color-primary-2)',
        padding: '2px',
    }),
    multiValueLabel: (provided: {}) => ({
        ...provided,
        color: 'var(--color-primary-3)',
    }),
    multiValueRemove: (provided: {}) => ({
        ...provided,
        'color': 'var(--color-primary-2)',
        'borderRadius': '0 2px 2px 0',
        '&:hover': {
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-primary-3)',
        },
    }),
    input: (provided: {}) => ({
        ...provided,
        '& input': {
            boxShadow: 'unset !important',
        },
    }),
    valueContainer: (provided: {}) => ({
        ...provided,
        padding: '2px 2px 2px 8px',
    }),
};

export default styles;
