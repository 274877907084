import Alpine from 'alpinejs';

type DataType = App.Context.Search.InstaSearch.Enums.InstaSearchDataType | '*';
type FieldKey = `${string}_${DataType}`;
type Content = Record<string, any>;

function fieldKey(field: string, dataType: App.Context.Search.InstaSearch.Enums.InstaSearchDataType | '*'): FieldKey {
    return `${field}_${dataType}`;
}

interface FieldDefinition {
    render(content: Content, field: string): string;
}

interface FieldDefinitions {
    add(key: FieldKey, definition: FieldDefinition): void;
    find(key: FieldKey): FieldDefinition;
}

class RuntimeFieldDefinitions implements FieldDefinitions {
    readonly definitions: { [key: FieldKey]: FieldDefinition };

    constructor() {
        this.definitions = {};
    }

    add(key: FieldKey, definition: FieldDefinition) {
        this.definitions[key] = definition;
    }

    find(key: FieldKey): FieldDefinition {
        return this.definitions[key];
    }
}

class FieldDefinitionForTitleAttributeOnAllDataTypes implements FieldDefinition {
    render(content: Content, field: string): string {
        return `<a class="underline font-bold hover:no-underline text-primary-2" href="${
            content['permalink'] ?? ''
        }" title="${content[field]}">${content[field]}</a>`;
    }
}

class FieldDefinitionForPermalinkAttributeOnAllDataTypes implements FieldDefinition {
    render(content: Content, field: string): string {
        return `<a href="${content[field]}" title="View" class="py-1 px-2 rounded-md bg-primary-2 text-white font-bold">View</a>`;
    }
}

class FieldDefinitionForFullNameAttributeOnMembershipDataType implements FieldDefinition {
    render(content: Content, field: string): string {
        return `<p class="mb-0 leading-none lg:leading-normal">${
            content[field]
        }</p><p class="m-0 leading-none"><span class="text-primary-3 text-3xs lg:text-2sx">${
            content['position'] ?? ''
        }</span></p>`;
    }
}

class FieldDefinitionForInstructorsAttributeOnProductDataType implements FieldDefinition {
    render(content: Content, field: string): string {
        return content[field]
            .map((instructor: { full_name: string; avatar_url: string }) => {
                return instructor.full_name;
            })
            .join(', ');
    }
}

class FieldDefinitionUsingAString implements FieldDefinition {
    render(content: Content, field: string): string {
        return content[field];
    }
}

class FieldDefinitionUsingAnArray implements FieldDefinition {
    render(content: Content, field: string): string {
        return content[field].join(', ');
    }
}

class FieldDefinitionComposite implements FieldDefinition {
    stringDefinition: FieldDefinition;
    arrayDefinition: FieldDefinition;

    constructor(stringDefinition: FieldDefinition, arrayDefinition: FieldDefinition) {
        this.stringDefinition = stringDefinition;
        this.arrayDefinition = arrayDefinition;
    }

    render(content: Content, field: string): string {
        const value = content[field];

        if (Array.isArray(value)) {
            return this.arrayDefinition.render(content, field);
        }

        return this.stringDefinition.render(content, field);
    }
}

/**
 * Custom alpine component to render individual search attribute
 * value.
 */
type Props = {
    rawValue: Content;
    field: string;
};

Alpine.data('instaSearchField', ({ rawValue, field }: Props) => {
    return {
        content: '',
        fieldDefinitions: {} as FieldDefinitions,
        init() {
            const fieldDefinitions = new RuntimeFieldDefinitions();

            fieldDefinitions.add(
                fieldKey('*', '*'),
                new FieldDefinitionComposite(new FieldDefinitionUsingAString(), new FieldDefinitionUsingAnArray()),
            );

            fieldDefinitions.add(fieldKey('title', '*'), new FieldDefinitionForTitleAttributeOnAllDataTypes());

            fieldDefinitions.add(fieldKey('permalink', '*'), new FieldDefinitionForPermalinkAttributeOnAllDataTypes());

            fieldDefinitions.add(
                fieldKey('full_name', 'Membership'),
                new FieldDefinitionForFullNameAttributeOnMembershipDataType(),
            );

            fieldDefinitions.add(
                fieldKey('instructors', 'Product'),
                new FieldDefinitionForInstructorsAttributeOnProductDataType(),
            );

            this.fieldDefinitions = fieldDefinitions;

            this.initContent(field, rawValue);
        },
        initContent(field: string, content: Content) {
            const foundDefinitionForDataType = this.fieldDefinitions.find(fieldKey(field, content['DataType']));

            if (foundDefinitionForDataType) {
                this.content = foundDefinitionForDataType.render(content, field);
                return;
            }

            const foundDefinitionForAllDataTypes = this.fieldDefinitions.find(fieldKey(field, '*'));

            if (foundDefinitionForAllDataTypes) {
                this.content = foundDefinitionForAllDataTypes.render(content, field);
            } else {
                this.content = this.fieldDefinitions.find(fieldKey('*', '*')).render(content, field);
            }
        },
        get isDisplayed(): boolean {
            const value = rawValue[field];

            if (Array.isArray(value) && value.length === 0) {
                return false;
            }

            if (value === null || value === undefined) {
                return false;
            }

            return true;
        },
        get isLabelDisplayed(): boolean {
            if (field === 'permalink') {
                return false;
            }

            return true;
        },
    };
});
