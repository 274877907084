import Alpine from 'alpinejs';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';

Alpine.data('repeater', () => ({
    blueprint: {} as Record<string, string>,
    rows: [] as Array<Record<string, string>>,
    name: '',

    init() {
        this.blueprint = JSON.parse(this.$el!.dataset.blueprint!);
        this.rows = JSON.parse(this.$el!.dataset.initialValue || '[]').map((row: object) => ({
            ...row,
            __frontUuid__: uuid(),
        }));
        this.name = this.$el!.dataset.name!;
    },

    addRow() {
        this.rows.push({ ...cloneDeep(this.blueprint), __frontUuid__: uuid() });
    },

    removeRow(index: number) {
        this.rows = this.rows.filter((_, i) => i !== index);
    },

    getName(index: number, name: string) {
        return `${this.name}[${index}][${name}]`;
    },
}));
