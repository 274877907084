import axios from 'axios';

export function postViewerEvent(
    event: App.Context.ELearning.Enums.ViewerEventType,
    sessionUuid: string,
    properties: Record<string, any> = {},
) {
    axios.post(`/internal-api/e-learning/${sessionUuid}/viewer-event`, {
        type: event,
        properties: JSON.stringify(properties),
    });

    if (window.Sail.environment.environment !== 'production') {
        console.log('Viewer Event: ' + event);
    }
}

export function registerViewerEvents(sessionUuid: string) {
    postViewerEvent('user_arrived', sessionUuid);

    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            postViewerEvent('user_tab_active', sessionUuid);

            return;
        }

        postViewerEvent('user_tab_inactive', sessionUuid);
    });

    window.addEventListener('beforeunload', () => {
        postViewerEvent('user_left', sessionUuid);
    });

    setInterval(() => postViewerEvent('user_heartbeat', sessionUuid), 60000);
}
