import React from 'react';
import { $$ } from 'front/util';
import { render } from 'react-dom';
import SiteSearch from './SiteSearch';

$$('[data-site-search]').forEach((siteSearch) => {
    render(<SiteSearch indexName={siteSearch.dataset.siteSearch!} />, siteSearch);
});

const searchToggles = document.querySelectorAll('.search-toggle-js');
const body = document.querySelector('body') as HTMLElement;

if (searchToggles.length && body) {
    searchToggles.forEach((searchToggle) => {
        searchToggle.addEventListener('click', (event) => {
            event.preventDefault();
            toggleSearch(body);
        });

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                if (body.classList.contains('search-is-open')) {
                    toggleSearch(body);
                }
            }
        });
    });
}

function toggleSearch(body: HTMLElement) {
    if (!body) {
        return;
    }

    if (body.classList.contains('nav-mobile-is-open')) {
        body.classList.remove('nav-mobile-is-open');
    }

    body.classList.toggle('search-is-open');
}
