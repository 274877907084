import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import TextInput from 'front/ui/forms/inputs/TextInput';
import type { SearchBoxProvided } from 'react-instantsearch-core';
import { Loader } from 'front/app/catalog/components/Loader';

function ClearSearch({ onClick }: { onClick: () => {} }) {
    return (
        <button
            type="button"
            onClick={onClick}
            className="absolute right-1.5 top-2.5 cursor-pointer text-white bg-primary-2 rounded-full w-4 h-4 text-[12px] leading-none flex items-center justify-center"
        >
            &times;
        </button>
    );
}

function SearchBox({ currentRefinement, isSearchStalled, refine }: SearchBoxProvided) {
    return (
        <form
            noValidate
            action=""
            role="search"
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <div className="form-input-has-icon">
                <div className="form-input-icon">
                    <i className="icon-search text-gray-3" />
                </div>
                <TextInput
                    value={currentRefinement}
                    onChange={(value) => refine(value)}
                    className="w-full"
                    placeholder="Type here. Ex. ”ethics”"
                />
                {isSearchStalled && <Loader />}
                {!isSearchStalled && currentRefinement !== '' && <ClearSearch onClick={() => refine('')} />}
            </div>
        </form>
    );
}

export default connectSearchBox(SearchBox);
