import * as Sentry from '@sentry/browser';

if (window.Sail.sentry.dsn) {
    Sentry.init({
        dsn: window.Sail.sentry.dsn,
        release: window.Sail.environment.appVersion,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                networkDetailAllowUrls: [''],
            }),
        ],
        tracesSampleRate: window.Sail.sentry.traces_sample_rate || 0.0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        environment: window.Sail.environment.environment,
    });

    if (window.Sail.tenant) {
        Sentry.setContext('tenant', {
            name: window.Sail.general.short_name,
            id: window.Sail.tenant.id,
        });

        Sentry.setTag('tenant', window.Sail.general.short_name);
    }

    if (window.Sail.sentry.logged_in) {
        Sentry.setUser({
            id: window.Sail.sentry.user_id || '',
            email: window.Sail.sentry.user_email || '',
            name: window.Sail.sentry.user_email,
            is_super_admin: window.Sail.sentry.user_is_super_admin,
            is_staff: window.Sail.sentry.user_is_staff,
        });
    }
}
