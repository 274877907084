// @ts-nocheck
import Button from 'front/ui/forms/inputs/Button';
import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

/** see example: @link https://www.algolia.com/doc/api-reference/widgets/clear-refinements/react/#full-example **/
export const CustomClearRefinements = connectCurrentRefinements(({ items, refine }) => {
    return (
        <div className={!items.length ? 'opacity-0 pointer-events-none' : ''}>
            <Button is="link" onClick={() => refine(items)} disabled={!items.length}>
                Reset filters
            </Button>
        </div>
    );
});
