// @ts-nocheck
import React from 'react';
import { connectStateResults, CurrentRefinements } from 'react-instantsearch-dom';
import { CustomInfiniteHits } from './infiniteHits';
export const CustomResultsSection = connectStateResults(({ searchState }) => {
    return (
        <div id="searchResultsComponents">
            <div className="my-4">
                <CurrentRefinements />
            </div>
            <CustomInfiniteHits />
        </div>
    );
});
