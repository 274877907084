// @ts-nocheck
import React from 'react';
import slugify from 'slugify';

export const LarutaRefinementListItem = ({
    staticItem,
    item,
    index,
    isRefined,
    refinementListProps,
    listItemIcon = null,
    afterRefinements = '',
}) => {
    // Generate a unique ID (because nested refinements trigger would their "parent checkbox")
    const getUniqueId = () => {
        return slugify('refinement-list-checkbox-for-' + staticItem.label, {
            lower: true,
        });
    };

    const getAfterRefinements = () => {
        if (afterRefinements) {
            return <div className="">{afterRefinements}</div>;
        }
    };

    const toggleEmptyResults = () => {
        return item && item.count > 0 ? 'show-count' : 'hide-count';
    };

    return (
        <div className="">
            <label
                key={index}
                className={`flex items-center gap-2 ${isRefined ? 'font-semibold' : ''} ${toggleEmptyResults()}`}
                htmlFor={getUniqueId()}
            >
                <input
                    type="checkbox"
                    className="form-checkbox"
                    id={getUniqueId()}
                    value={staticItem.label}
                    checked={isRefined}
                    onChange={(event) => {
                        const value = event.currentTarget.value;
                        const next = refinementListProps.currentRefinement.includes(value)
                            ? refinementListProps.currentRefinement.filter((current) => current !== value)
                            : refinementListProps.currentRefinement.concat(value);
                        refinementListProps.refine(next);
                    }}
                />
                {listItemIcon}
                {staticItem.label} <span className="text-gray-3">({item ? item.count : '0'})</span>
            </label>
            {getAfterRefinements()}
        </div>
    );
};
