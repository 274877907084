// @ts-nocheck
import React from 'react';
import { BlogPostHit } from './hits/BlogPostHit';
import { CustomizableCourseHit } from './hits/CustomizableCourseHit';
import { DefaultHit } from './hits/DefaultHit';
import { EthicsOpinionHit } from './hits/EthicsOpinionHit';
import { EventHit } from './hits/EventHit';
import { OnDemandHit } from './hits/OnDemandHit';

export function HitFactory(props) {
    switch (props.hit.DataType) {
        case 'Blog Post':
            return <BlogPostHit hit={props.hit} />;

        case 'Customizable Course':
            return <CustomizableCourseHit hit={props.hit} />;

        case 'Ethics Opinion':
            return <EthicsOpinionHit hit={props.hit} />;

        case 'Event':
            return <EventHit hit={props.hit} />;

        case 'On-Demand':
            return <OnDemandHit hit={props.hit} />;

        case 'Product':
        case 'Page':
        default:
            return <DefaultHit hit={props.hit} />;
    }
}
