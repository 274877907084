import Alpine from 'alpinejs';
import anime from 'animejs';

Alpine.data('superNavLinks', () => ({
    showArrowButtons: false,

    mount(refs: Record<string, HTMLElement>) {
        this.showArrowButtons = this.linksAreOverflowing(refs);
    },

    linksAreOverflowing(refs: Record<string, HTMLElement>) {
        return refs.links.offsetWidth >= this.availableWidthForLinks(refs);
    },

    availableWidthForLinks(refs: Record<string, HTMLElement>) {
        return (
            refs.container.parentElement!.offsetWidth -
            document.getElementById('account-menu-and-membership-badge')!.offsetWidth
        );
    },

    setVisibleWidth(refs: Record<string, HTMLElement>) {
        return 'width: ' + (this.availableWidthForLinks(refs) - 50) + 'px;';
    },

    scroll(refs: Record<string, HTMLElement>, direction: string) {
        const scrollLeftValue = direction === 'left' ? refs.links.scrollLeft - 75 : refs.links.scrollLeft + 75;

        anime({
            targets: refs.links,
            scrollLeft: scrollLeftValue,
            duration: 250,
            easing: 'linear',
        });
    },
}));
