import React from 'react';
import { DateTime as Luxon } from 'luxon';
import type { Document } from 'front/app/catalog/types';
import { sum } from 'lodash';

export default function Result({
    hit: event,
    timezone,
    creditHourLabel,
    displayCreditHourType,
}: {
    hit: Document;
    timezone: string;
    creditHourLabel: string;
    displayCreditHourType: boolean;
}) {
    function getDate(date: number) {
        // Force non-dated products to end
        if (date === 9999999999) {
            return null;
        }

        return Luxon.fromMillis(date * 1000, { zone: timezone });
    }

    const startDay = getDate(event.start!)?.toFormat('d');
    const startMonth = getDate(event.start!)?.toFormat('MMM');
    const startYear = getDate(event.start!)?.toFormat('yyyy');

    const endDay = getDate(event.end!)?.toFormat('d');
    const endMonth = getDate(event.end!)?.toFormat('MMM');
    const endYear = getDate(event.end!)?.toFormat('yyyy');

    return (
        <a href={event.url} className="event-card">
            {event.start !== 9999999999 && (
                <div className="event-card-date">
                    {startMonth === endMonth ? (
                        <div>{startMonth}</div>
                    ) : (
                        <div>
                            {startMonth}-{endMonth}
                        </div>
                    )}

                    {startDay === endDay ? (
                        <div className="event-card-day">{startDay}</div>
                    ) : (
                        <div className="event-card-day">
                            {startDay}-{endDay}
                        </div>
                    )}

                    {startYear === endYear ? (
                        <div>{startYear}</div>
                    ) : (
                        <div>
                            {startYear}-{endYear}
                        </div>
                    )}
                </div>
            )}

            <div className="event-card-main">
                <div>
                    <p className="event-card-delivery">
                        {event.deliveryFormatIcon && <span className={`icon-${event.deliveryFormatIcon}`} />}
                        <span>{event.deliveryFormat}</span>
                    </p>
                    <span className="event-card-title" title={event.name}>
                        {event.name}
                    </span>
                </div>

                <hr className="event-card-hr" />

                <ul className="event-card-props">
                    {event.instructors.length > 1 && <li>Speaker: Various</li>}
                    {event.instructorsWithDetails.length === 1 && (
                        <li className="flex items-center">
                            {event.instructorsWithDetails[0].avatar_url && (
                                <div className="w-4 h-4 sm:w-8 sm:h-8 rounded-full bg-gray-1 mr-1 sm:mr-2 overflow-hidden">
                                    <img
                                        src={event.instructorsWithDetails[0].avatar_url}
                                        className="min-h-full object-cover"
                                    />
                                </div>
                            )}
                            {event.instructorsWithDetails[0].name}
                        </li>
                    )}
                    {event.start !== 9999999999 && <li>{getDate(event.start!)?.toFormat('h:mm a ZZZZ')}</li>}
                    {event.type && <li>{event.type}</li>}
                    {event.location && <li>{event.location}</li>}
                    {!!event.totalCreditHours && event.totalCreditHours.length > 0 && (
                        <li>
                            <div className="flex gap-2">
                                {displayCreditHourType
                                    ? // @ts-expect-error this event isn't typed
                                      event.totalCreditHours.map((creditHours, index) => {
                                          return <div key={index}>{`${creditHours.total} (${creditHours.type})`}</div>;
                                      })
                                    : // @ts-expect-error this event isn't typed
                                      sum(event.totalCreditHours.map((creditHours) => creditHours.total)) +
                                      ' ' +
                                      creditHourLabel}
                            </div>
                        </li>
                    )}
                </ul>
                {event.fieldsOfStudy.length > 0 && (
                    <div className="event-card-fos">
                        <p className="uppercase">Fields Of Study:</p>
                        <ul className="event-card-fos-ul">
                            {event.fieldsOfStudy.map((fieldOfStudy, index) => (
                                <li key={index} className="event-card-fos-tag">
                                    {fieldOfStudy}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </a>
    );
}
