import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { Auth0LockPasswordless } from 'auth0-lock';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

// Code ported from LaRuta Auth0 integration.
// See https://github.com/blueoceanideas/laruta.io/blob/5dbbefa253c4e914d5ad68a7f69990675604c95e/web/app/themes/laruta-themosis-parent/assets/js/util/LarutaAuth0.js

class SailAuth0 {
    // Holds user's typed input for the email field
    userEmailInput: string | null = null;

    // Allows tracking/resetting of the "No User" message in the Lock modal
    noUserMessageDisplayed: boolean = false;

    lock: Auth0LockPasswordlessStatic | null = null;

    constructor() {
        this.triggerModalFromCustomButtons();

        if (this.onLoginPage()) {
            this.showLock();
        }
    }

    onLoginPage() {
        return window.location.pathname === '/login' || window.location.pathname === '/staff-login';
    }

    setAuthCookie() {
        const cookie = uuidv4();
        Cookies.set('auth-token', cookie, { expires: 1, secure: true });

        return cookie;
    }

    showLock() {
        // For safety reasons the state should be validated on the callback, but this happens in PHP
        const cookie = this.setAuthCookie();

        this.lock = new Auth0LockPasswordless(window.Sail.auth0.client_id, window.Sail.auth0.domain, {
            auth: {
                responseMode: 'form_post',
                responseType: 'token',
                redirectUrl: window.Sail.auth0.redirect_url,
                params: {
                    scope: 'openid email',
                    state: cookie,
                },
            },
            avatar: this.overrideAvatar(),
            showTerms: false,
            closable: !this.onLoginPage(),
            languageDictionary: {
                title: 'Login',
                passwordlessEmailAlternativeInstructions: 'Otherwise, enter your email to sign in or create an account',
                loginWithLabel: 'Login with %s',
                signUpWithLabel: 'Login with %s',
            },
            theme: {
                logo: window.Sail.auth0.logo,
                primaryColor: getComputedStyle(document.documentElement).getPropertyValue('--color-primary-2'),
            },
        });

        this.addEventListeners();

        this.overrideSubmit = this.overrideSubmit.bind(this);

        this.lock.show();
    }

    triggerModalFromCustomButtons() {
        document.addEventListener('click', (event: MouseEvent) => {
            if ((event.target as HTMLButtonElement).closest('[data-auth0-login-button]')) {
                event.preventDefault();

                this.showLock();
            }
        });
    }

    addEventListeners() {
        // Auth0 event info can be found at https://auth0.com/docs/libraries/lock/lock-api-reference
        this.lock?.on('socialOrEmail ready', () => {
            // Our customizations for the Lock modal is initialized here.
            this.createLoadingIndicator();
            this.createUserExistsElement();
            this.addEmailInputListeners();
            this.addOverrideSubmitListener();
            this.noUserMessageDisplayed = false;
        });

        this.lock?.on('hide', () => {
            this.removeOverrideSubmitListener();
            this.noUserMessageDisplayed = false;
        });

        this.lock?.on('vcode ready', () => {
            this.resetSubmitButton();
            this.noUserMessageDisplayed = false;
        });
    }

    createUserExistsElement() {
        const message = "<div class='auth0-no-user-message'></div>";

        const userExistsElement = document.createElement('div');
        userExistsElement.setAttribute('id', 'auth0LockUserCheck');
        userExistsElement.classList.add('auth0-lock-user-check');
        userExistsElement.innerHTML = message;

        const auth0LockContentElement = document.querySelector(
            '.auth0-lock .auth0-lock-content .auth0-lock-input-email',
        );

        if (auth0LockContentElement && !auth0LockContentElement.contains(userExistsElement)) {
            auth0LockContentElement.appendChild(userExistsElement);
        }
    }

    attachNewAccountMessage() {
        // Don't do anything if the email input field is empty or not a valid email address
        if (!this.isUserInputValidEmailAddress()) {
            return;
        }

        this.hideLoadingIndicator();

        const auth0LockUserCheck = document.getElementById('auth0LockUserCheck');
        if (!auth0LockUserCheck) return;

        const messageElement = auth0LockUserCheck.querySelector('.auth0-no-user-message');
        if (!messageElement) return;

        messageElement.innerHTML = this.getNoUserFoundMessage();

        auth0LockUserCheck.style.display = 'block';
        auth0LockUserCheck.style.opacity = '1';

        auth0LockUserCheck.scrollIntoView(true);
    }

    detachNewAccountMessage() {
        const auth0LockUserCheck = document.getElementById('auth0LockUserCheck');
        if (!auth0LockUserCheck) return;

        auth0LockUserCheck.style.opacity = '0';
        auth0LockUserCheck.style.display = 'none';
    }

    addEmailInputListeners() {
        const userEmailInputElement = document.querySelector('.auth0-lock input[type=email][id$=email]');
        if (!userEmailInputElement) return;

        userEmailInputElement.addEventListener('keyup', (event) => {
            this.userEmailInput = (event.target as HTMLInputElement).value;
            this.detachNewAccountMessage();

            if (this.noUserMessageDisplayed) {
                this.resetSubmitButton();
                this.addOverrideSubmitListener();
                this.noUserMessageDisplayed = false;
            }
        });

        userEmailInputElement.addEventListener('click', (event) => {
            // Remove a readonly attribute that gets added after clicking "submit"
            if ((event.target as HTMLInputElement).hasAttribute('readonly')) {
                (event.target as HTMLInputElement).removeAttribute('readonly');
            }
        });
    }

    createLoadingIndicator() {
        const auth0LockContentElement = document.querySelector(
            '.auth0-lock .auth0-lock-content .auth0-lock-input-email',
        );
        if (!auth0LockContentElement) return;
        if (auth0LockContentElement.querySelector('#auth0LoadingIndicator')) return;

        const loadingIndicator = document.createElement('div');
        loadingIndicator.setAttribute('id', 'auth0LoadingIndicator');
        loadingIndicator.classList.add('auth0-loading-indicator');
        loadingIndicator.innerHTML =
            "<img src='https://assets.sailamx.com/assets/gifs/loading-fountain.gif' style='margin-bottom: 10px; width: 33%;'>";

        auth0LockContentElement.appendChild(loadingIndicator);
    }

    showLoadingIndicator() {
        const loadingIndicator = document.getElementById('auth0LoadingIndicator');
        if (!loadingIndicator) return;
        loadingIndicator.style.display = 'flex';
    }

    hideLoadingIndicator() {
        const loadingIndicator = document.getElementById('auth0LoadingIndicator');
        if (!loadingIndicator) return;
        loadingIndicator.style.display = 'none';
    }

    isUserInputValidEmailAddress() {
        return this.userEmailInput !== null && this.validateEmail(this.userEmailInput);
    }

    overrideAvatar() {
        return {
            // Get the Avatar Url
            // eslint-disable-next-line @typescript-eslint/ban-types
            url: (email: string, callback: Function) => {
                // Prevent Fetching if there is no email valid.
                if (!this.validateEmail(email)) {
                    return;
                }

                axios
                    .post('/internal-api/user-exists', { email: email })
                    .then((response) => (response.status === 200 ? response.data : { exists: false }))
                    .then((data) => {
                        if (data.exists) {
                            callback(null, data.avatar_url);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        Sentry.captureException(error);
                    });
            },

            // Get the Display Name. I know, this code is almost a repeat of the above, but this is how Auth0 Lock does things.
            // eslint-disable-next-line @typescript-eslint/ban-types
            displayName: (email: string, callback: Function) => {
                if (!this.validateEmail(email)) {
                    return;
                }

                axios
                    .post('/internal-api/user-exists', { email: email })
                    .then((response) => (response.status === 200 ? response.data : { exists: false }))
                    .then((data) => {
                        if (data.exists === true) {
                            callback(null, data.display_name);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        Sentry.captureException(error);
                    });
            },
        };
    }

    getSubmitButton() {
        return document.querySelector('.auth0-lock-submit') as HTMLButtonElement | null;
    }

    addOverrideSubmitListener() {
        this.getSubmitButton()?.addEventListener('click', this.overrideSubmit);
    }

    removeOverrideSubmitListener() {
        this.getSubmitButton()?.removeEventListener('click', this.overrideSubmit);
    }

    overrideSubmit(event: MouseEvent) {
        // Cancel the default behavior, which would send the user to the confirmation code screen
        event.preventDefault();

        // If the 'No User' message is attached, remove it
        this.detachNewAccountMessage();

        // Disable Typing any more characters in input
        this.disableTyping();

        // Show the loading indicator
        this.showLoadingIndicator();

        // Check for user
        axios
            .post('/internal-api/user-exists', { email: this.userEmailInput })
            .then((response) => (response.status === 200 ? response.data : { exists: false }))
            .then((data) => {
                // Remove the submit button action override
                this.removeOverrideSubmitListener();

                // If a user is found, or a user was not found but they already saw the 'No User' message,
                // then proceed to confirmation code screen.
                if (data.exists || (!data.exists && this.noUserMessageDisplayed)) {
                    this.getSubmitButton()?.click();

                    return;
                }

                // If a user is not found, display the 'No User' Message
                if (!data.exists) {
                    this.attachNewAccountMessage();

                    if (!this.isUserInputValidEmailAddress()) {
                        this.getSubmitButton()?.click();

                        this.hideLoadingIndicator();

                        return;
                    }

                    this.updateSubmitButtonToConfirmButton();

                    // Mark that the 'No User' message was shown
                    this.noUserMessageDisplayed = true;
                }
            })
            .catch((error) => {
                console.error(error);
                Sentry.captureException(error);
            });
    }

    // Changes the text from Submit to Confirm if the 'No User' message is displayed
    updateSubmitButtonToConfirmButton() {
        const submitButton = this.getSubmitButton();
        if (!submitButton) return;

        const submitButtonLabel = document.querySelector('.auth0-lock-submit > .auth0-label-submit');
        if (!submitButtonLabel) return;

        submitButton.classList.add('confirm-account');
        submitButtonLabel.innerHTML = 'Create Account';
    }

    // Resets Confirm text back to Submit
    resetSubmitButton() {
        const submitButton = this.getSubmitButton();
        if (!submitButton) return;

        const submitButtonLabel = document.querySelector('.auth0-lock-submit > .auth0-label-submit');
        if (!submitButtonLabel) return;

        submitButton.classList.remove('confirm-account');
        submitButtonLabel.innerHTML = 'Submit';
    }

    getNoUserFoundMessage() {
        const message =
            '<p>There is no account attached to:<br>{{EMAIL_ADDRESS}}</p><p>Click "create account" to create a new account, or try another email if you have an existing account.</p><p>If you know you have an account but don\'t recall the email address, please <a href="/contact" target="blank">contact our member service team</a></p>';

        if (!message) {
            return '';
        }

        const replacement = this.userEmailInput
            ? "<span class='user-exists-email-address'>" + this.userEmailInput + '</span>'
            : 'the email you entered';

        return message.replace('{{EMAIL_ADDRESS}}', replacement);
    }

    disableTyping() {
        const userEmailInput = document.querySelector('.auth0-lock input[type=email][id$=email]');
        if (!userEmailInput) return false;
        if (userEmailInput.hasAttribute('readonly')) {
            return false;
        }

        userEmailInput.setAttribute('readonly', 'readonly');

        return true;
    }

    validateEmail(email: string): boolean {
        return email.indexOf('@') !== -1 && email.indexOf('.') !== -1;
    }
}

new SailAuth0();
