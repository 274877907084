// @ts-nocheck
import React from 'react';
import { LarutaHit } from './larutaHit';

export const OnDemandHit = ({ hit }) => (
    <LarutaHit
        hitName={hit.name}
        hitDataType={hit.DataType}
        hitDetails={[hit.Location, hit.Credits]}
        hitPermalink={hit.Permalink}
    />
);
