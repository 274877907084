import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import type { MenuProvided } from 'react-instantsearch-core';
import anime from 'animejs';

function Tab({
    active,
    children,
    onClick,
}: {
    active: boolean;
    children: string | React.ReactElement;
    onClick: () => {};
}) {
    return (
        <button
            className={`
                rounded-t mb-0 text-base leading-none font-light focus:outline-none transition-opacity duration-300
                ${
                    active
                        ? 'bg-white text-primary-2 opacity-1'
                        : 'bg-none text-white opacity-50 hover:text-primary-2 hover:bg-white hover:opacity-75 '
                }
            `}
            onClick={onClick}
            type="button"
        >
            <div
                className={`${
                    active ? 'border-b-2 border-primary-2' : ''
                } font-medium whitespace-nowrap mx-5 py-4 pointer-events-none`}
            >
                {children}
            </div>
        </button>
    );
}

function RefinementTabs({ currentRefinement, refine, tabs }: MenuProvided & { tabs: Array<string> }) {
    function scrollLeft() {
        const tabsContainer = document.getElementById('catalog-refinement-tabs');
        if (!tabsContainer) {
            return;
        }

        anime({
            targets: tabsContainer,
            scrollLeft: tabsContainer.scrollLeft - 150,
            duration: 250,
            easing: 'linear',
        });
    }

    function scrollRight() {
        const element = document.getElementById('catalog-refinement-tabs');
        if (!element) {
            return;
        }

        anime({
            targets: element,
            scrollLeft: element.scrollLeft + 150,
            duration: 250,
            easing: 'linear',
        });
    }

    return (
        <div className="flex justify-between mb-4 -mx-4 sm:-mx-6 md:mx-0">
            <button
                id="refinement-tabs-mobile-scroll-left"
                className="flex items-center justify-center relative z-10 md:hidden text-primary-2 bg-white border-2 border-b-0 border-l-0 border-primary-2 rounded-tr-md p-2"
                onClick={() => scrollLeft()}
            >
                <span className="icon-caret-left block" />
            </button>
            <div
                id="catalog-refinement-tabs"
                className="max-w-screen-xl flex justify-start overflow-x-scroll scrollbar-none px-4 pt-1 md:p-0 "
            >
                <Tab active={currentRefinement === null} onClick={() => refine(null)}>
                    All Events & Products
                </Tab>
                {tabs.map((tab) => (
                    <Tab key={tab} active={currentRefinement === tab} onClick={() => refine(tab)}>
                        {tab}
                    </Tab>
                ))}
            </div>
            <button
                id="refinement-tabs-mobile-scroll-right"
                className="flex items-center justify-center relative z-10 md:hidden text-primary-2 bg-white border-2 border-b-0 border-r-0 border-primary-2 rounded-tl-md p-2"
                onClick={() => scrollRight()}
            >
                <span className="icon-caret-right block" />
            </button>
        </div>
    );
}

export default connectMenu(RefinementTabs);
