import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import Alpine from 'alpinejs';
import type { VideoJsPlayer } from 'video.js';
/* import('videojs-http-source-selector');
import('videojs-contrib-quality-levels') */

Alpine.data('videoondemand', () => ({
    player: null as null | (VideoJsPlayer & { el_: HTMLVideoElement }),
    timeReached: 0,
    totalTime: 0,
    muxPlaybackId: '',
    lessonUuid: '',
    disabledProgressBar: null as null | HTMLDivElement,
    isDisplayingResumeButtons: false,

    getDataAttribute(attributeName: string, defaultValue?: string) {
        const value = this.$el!.dataset[attributeName];

        if (!value) {
            if (defaultValue) {
                return defaultValue;
            }

            throw new Error(`Required attribute ${attributeName} not found on the component.`);
        }

        return value;
    },

    init() {
        const videoElement = this.$el!.querySelector('video');
        if (!videoElement) {
            throw new Error('No <video> element found in the children of the Alpine video component');
        }

        this.muxPlaybackId = this.$el!.dataset.muxPlaybackId || '';
        this.lessonUuid = this.$el!.dataset.lessonUuid || '';

        if (!this.muxPlaybackId) {
            throw new Error('No data-mux-playback-id attribute found on the Alpine video component');
        }

        // Initialize videojs player
        this.player = videojs(videoElement, {
            plugins: {
                mux: {
                    data: { env_key: this.getDataAttribute('muxKey'), video_title: this.getDataAttribute('title', '') },
                },
                httpSourceSelector: {},
            },
            timelineHoverPreviews: false,
            poster: this.$el!.dataset.posterImageUrl || undefined,
            controlBar: {
                fullscreenToggle: true,
                volumePanel: true,
                playToggle: true,
                currentTimeDisplay: true,
                timeDivider: true,
                durationDisplay: true,
                progressControl: true,
                remainingTimeDisplay: true,
                liveDisplay: false,
                seekToLive: false,
                customControlSpacer: false,
                playbackRateMenuButton: false,
                chaptersButton: false,
                descriptionsButton: false,
                subsCapsButton: true,
                audioTrackButton: false,
                pictureInPictureToggle: true,
            },
            fluid: true,
            // @ts-expect-error Todo
            smoothQualityChange: true,
            handleManifestRedirects: true,
        });

        this.player!.src({ type: 'video/mux', src: this.muxPlaybackId });
        this.timeReached = this.getTimeReached();

        if (this.timeReached) {
            this.isDisplayingResumeButtons = true;
        }

        this.player.one('loadedmetadata', () => {
            this.totalTime = this.player?.duration() || 0;

            if (this.$el?.dataset.lessonCompleted === 'true') {
                this.timeReached = this.totalTime;
            }

            this.addDisabledProgressBar();
        });

        this.player.on('seeking', () => {
            const now = this.player?.currentTime() || 0;
            if (now > this.timeReached) {
                // @TODO: display warning that seeking forward is not allowed (flash)
                this.player?.currentTime(this.timeReached);
            }
        });

        setInterval(() => {
            const currentTime = this.player?.currentTime() || 0;
            this.updateTimeReached(currentTime);
        }, 500);
    },

    restart() {
        this.player?.currentTime(0);
        this.player?.play();
        this.isDisplayingResumeButtons = false;
        this.updateTimeReached(0);
    },

    resume() {
        this.player?.currentTime(this.timeReached);
        this.player?.play();
        this.isDisplayingResumeButtons = false;
    },

    addDisabledProgressBar() {
        this.disabledProgressBar = document.createElement('div');
        this.disabledProgressBar.style.height = '100%';
        this.disabledProgressBar.style.backgroundColor = 'red';
        this.disabledProgressBar.style.right = '0';
        this.disabledProgressBar.style.position = 'absolute';

        this.$el?.querySelector('div.vjs-progress-holder')?.appendChild(this.disabledProgressBar);
    },

    updateDisabledProgressBar() {
        if (this.disabledProgressBar) {
            this.disabledProgressBar.style.width = ((this.totalTime - this.timeReached) / this.totalTime) * 100 + '%';
        }
    },

    updateTimeReached(time: number) {
        if (time > this.timeReached) {
            this.timeReached = time;
            this.storeTimeReached(time);
            this.updateDisabledProgressBar();
        }
    },

    storeTimeReached(time: number) {
        localStorage.setItem('time-reached_' + this.lessonUuid, time.toString());
    },

    getTimeReached(): number {
        try {
            const savedTime = parseFloat(localStorage.getItem('time-reached_' + this.lessonUuid) || '0');

            return savedTime < 15 ? 0 : savedTime;
        } catch (error) {
            return 0;
        }
    },
}));
