import React from 'react';
import { connectHits, connectSearchBox } from 'react-instantsearch-dom';
import Result from './Result';
import type { HitsProvided, SearchBoxProvided } from 'react-instantsearch-core';

function ResultList({
    hits,
    isSearchStalled,
    timezone,
    creditHourLabel,
    displayCreditHourType,
}: HitsProvided<any> &
    SearchBoxProvided & {
        timezone: string;
        creditHourLabel: string;
        displayCreditHourType: boolean;
    }) {
    return (
        <div>
            <div className="space-y-6">
                {hits.map((hit) => (
                    <Result
                        key={hit.objectID}
                        hit={hit}
                        timezone={timezone}
                        creditHourLabel={creditHourLabel}
                        displayCreditHourType={displayCreditHourType}
                    />
                ))}
                {hits.length === 0 && !isSearchStalled && <div>No results.</div>}
            </div>
        </div>
    );
}

export default connectSearchBox(connectHits(ResultList));
