import Alpine from 'alpinejs';
import Echo from 'laravel-echo';
import { DateTime as Luxon } from 'luxon';
import { fixTimezone } from '../../util';
import { registerViewerEvents } from '../eLearning/viewerEvents';

Alpine.data('countdown', () => ({
    eventSessionUuid: '',
    streamUuid: '',
    startsAt: null as Luxon | null,

    init() {
        this.eventSessionUuid = this.$el!.dataset.eventSessionUuid!;
        this.streamUuid = this.$el!.dataset.streamUuid!;
        this.startsAt = Luxon.fromSQL(this.$el!.dataset.startsAt!);

        const echo = new Echo({
            broadcaster: 'pusher',
            key: this.$el!.dataset.pusherKey!,
            cluster: this.$el!.dataset.pusherCluster!,
        });

        echo.channel(`livestreams.${this.streamUuid}`).listen('.PreStreamStartedEvent', () => {
            window.location.reload();
        });

        if (this.startsAt === null) {
            return;
        }

        const interval = setInterval(() => {
            const timeNow = fixTimezone(this.$el!.dataset.timezone!);
            if (timeNow >= this.startsAt!) {
                clearInterval(interval);
                window.location.reload();
            }
        }, 1000);

        registerViewerEvents(this.eventSessionUuid);
    },
}));
