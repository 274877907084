// @ts-nocheck
import React from 'react';
import { LarutaHit } from './larutaHit';

export const BlogPostHit = ({ hit }) => (
    <LarutaHit
        hitName={hit.name}
        hitDataType={hit.DataType}
        hitDetails={[hit.Date, hit.BlogCategory]}
        hitPermalink={hit.Permalink}
    />
);
